import React, { useContext } from 'react';
import { Button, Display, Text, Card } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { CardResumoProduto } from 'seguros/components/CardResumoProduto/CardResumoProduto';
import { CertificadoEmissaoProps } from 'seguros/types/CertificadoVidaTypes';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import { useDadosSegurado } from 'seguros/hooks';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { TipoSeguro } from 'seguros/types/DadosSegurado';

export const CertificadoEmissao: React.FC<CertificadoEmissaoProps> = ({
  error,
  gerarPdf,
  loading,
  existsPdf,
  messageService,
}) => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  const { clienteVida } = useContext(AppContext);

  const { dadosSegurado, loadingDadosSegurado } = useDadosSegurado();

  const emailDefault = dadosSegurado?.entidade?.email;

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.EMISSAO_CERTIFICADO_VIDA,
    parametrosEnvio: {
      numeroCertificadoBilhete: clienteVida.numCertificado,
      codigoProduto: clienteVida.codigoProduto,
    },
  };

  return (
    <>
      <RenderConditional condition={!loading}>
        <Display type="display-block">
          <CardResumoProduto />
          <RenderConditional condition={!error}>
            <Card>
              <Card.Content padding={[6, 8, 6, 8]}>
                <Text variant="headline-05" color="primary" margin>
                  Emissão de Certificado
                </Text>

                <RenderConditional condition={existsPdf}>
                  <Text variant="body02-sm" margin>
                    Você pode visualizar o certificado:
                  </Text>

                  <Display>
                    <Button
                      variant="outlined"
                      onClick={goDadosSeguradoVida}
                      data-testid="btnVoltar"
                    >
                      Voltar
                    </Button>
                    <EmailSenderModal
                      objetoEmail={objetoEmail}
                      defaultEmail={emailDefault}
                      disabled={loadingDadosSegurado}
                      seguro={TipoSeguro.VIDA}
                    />
                    <Button
                      variant="secondary"
                      onClick={gerarPdf}
                      loading={loading}
                      disabled={loading}
                    >
                      Emitir
                    </Button>
                  </Display>
                </RenderConditional>

                <RenderConditional condition={!existsPdf}>
                  <Text variant="body02-sm" align="center" margin>
                    {tryGetValueOrDefault([messageService], '-')}
                  </Text>

                  <Display>
                    <Button
                      variant="outlined"
                      onClick={goDadosSeguradoVida}
                      data-testid="btnVoltar"
                    >
                      Voltar
                    </Button>
                  </Display>
                </RenderConditional>
              </Card.Content>
            </Card>
          </RenderConditional>
        </Display>
      </RenderConditional>
      <RenderConditional condition={loading}>
        <SkeletonLoading blocks={2} />
      </RenderConditional>
    </>
  );
};
