import React, { useMemo } from 'react';
import { Text } from '@cvp/design-system/react';
import { TableExtendita } from 'portabilidade/components/Table';
import { formatarDataHora } from 'main/utils';
import {
  IDetalhesProspeccao,
  SituacaoProspeccao,
} from 'main/features/prospeccao/types/IDetalhesProspeccao';
import { IDataTableColumn } from 'react-data-table-component';

type HistoricoSituacaoProspeccaoProps = {
  dadosProspeccao?: IDetalhesProspeccao;
};

const HistoricoSituacaoProspeccao: React.FunctionComponent<
  HistoricoSituacaoProspeccaoProps
> = ({ dadosProspeccao }) => {
  const colunas = useMemo(
    (): IDataTableColumn<SituacaoProspeccao>[] => [
      {
        name: 'Situação',
        selector: row => row.descricao,
        sortable: false,
        cell: ({ descricao, situacaoVigente }: SituacaoProspeccao) => (
          <Text
            variant="body02-sm"
            color={situacaoVigente ? 'success' : 'text'}
          >
            {descricao}
          </Text>
        ),
      },
      {
        name: 'Data e hora do registro',
        selector: row => row.dataHoraOcorrencia,
        cell: ({ dataHoraOcorrencia, situacaoVigente }: SituacaoProspeccao) => (
          <Text
            variant="body02-sm"
            color={situacaoVigente ? 'success' : 'text'}
          >
            {formatarDataHora(dataHoraOcorrencia)}
          </Text>
        ),

        sortable: true,
      },
      {
        name: 'Responsável',
        selector: row => row.descricao,
        sortable: false,
        cell: ({ usuarioResponsavel, situacaoVigente }: SituacaoProspeccao) => (
          <Text
            variant="body02-sm"
            color={situacaoVigente ? 'success' : 'text'}
          >
            {usuarioResponsavel}
          </Text>
        ),
      },
    ],
    [],
  );
  return (
    <TableExtendita
      columns={colunas}
      noHeader
      title="Histórico da Situação"
      data={dadosProspeccao?.situacoes ?? []}
      noDataComponent="Não há dados para exibir."
    />
  );
};

export default HistoricoSituacaoProspeccao;
