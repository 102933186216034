import { IDataTableColumn } from 'react-data-table-component';
import { TSolicitacaoResgateDataColumn } from '../types/ResponseCancelamentoSolicitacaoResgate';

export const COLUNAS_SOLICITACAO_RESGATE: IDataTableColumn<TSolicitacaoResgateDataColumn>[] =
  [
    {
      name: 'Data Solicitação',
      selector: row => row.dataSolicitacao,
      wrap: true,
      width: '140px',
      sortable: true,
    },
    {
      name: 'Tipo de Resgate',
      selector: row => row.tipoResgate,
      width: '120px',
      sortable: true,
    },
    {
      name: 'Valor do Resgate',
      selector: row => row.valor,
      width: '150px',
      sortable: true,
    },
    {
      name: 'Situação',
      selector: row => row.status,
      minWidth: '100px',
      sortable: true,
    },
    {
      name: 'Ação',
      selector: row => row.acao,
      minWidth: '140px',
    },
  ];

export const TEXTOS_CANCELAMENTO = {
  TITULO: 'Histórico e Cancelamento de Resgate',
  DESCRICAO:
    'Veja o histórico de solicitações feitas para o certificado ou cancele uma solicitação de resgate.',
};
