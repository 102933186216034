import { levelRisk } from 'previdencia/utils/riskStatus';
import ColunaSelecionarFundo from '../components/TabelaDistribuicaoEntreFundos/ColunaSelecionarFundo';
import {
  IDadosTabelaDistribuicaoProps,
  TTabelaDistribuicao,
} from '../types/TableColumns';
import RiskBar from 'previdencia/components/RiskBar';
import ColunaPercentualDistribuicao from '../components/TabelaDistribuicaoEntreFundos/ColunaPercentualDistribuicao';
import ColunaValorTotal from '../components/TabelaDistribuicaoEntreFundos/ColunaValorTotal';
import { IFundo } from '../types/VGBLListarOfertas';

export const colunasTabelaDistribuicaoFundo: TTabelaDistribuicao = ({
  handlers,
  requisitosDiversificacao,
}: IDadosTabelaDistribuicaoProps) => [
  {
    name: '',
    selector: (row: IFundo) => row.check,
    width: '20px',
    cell: (row: IFundo) => (
      <ColunaSelecionarFundo
        row={row}
        handlers={handlers}
        requisitosDiversificacao={requisitosDiversificacao}
      />
    ),
  },
  {
    name: 'Fundos',
    selector: row => row.descricaoFundo,
    maxWidth: '280px',
    wrap: true,
  },
  {
    name: 'Perfil de Risco',
    selector: row => row.perfilRiscoNivel,
    maxWidth: '150px',
    center: true,
    cell: (row: IFundo) => <RiskBar risk={levelRisk(row.perfilRiscoNivel)} />,
  },
  {
    name: 'Rentabilidade (Últimos 12 Meses)',
    selector: row => row.percentualRentabilidadeUltimoAno,
    maxWidth: '180px',
    center: true,
  },
  {
    name: 'Percentual (totalizar 100%)',
    selector: (row: IFundo) => row.vlrSaldo,
    maxWidth: '180px',
    center: true,
    cell: (row: IFundo) => (
      <ColunaPercentualDistribuicao
        row={row}
        handlers={handlers}
        requisitosDiversificacao={requisitosDiversificacao}
      />
    ),
  },
  {
    name: 'Valor total',
    selector: (row: IFundo) => row.valorTransferencia,
    center: true,
    cell: (row: IFundo) => (
      <ColunaValorTotal
        row={row}
        handlers={handlers}
        requisitosDiversificacao={requisitosDiversificacao}
      />
    ),
  },
];
