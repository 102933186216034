import React, { useContext } from 'react';
import { CardResumoPrestamista } from 'prestamista/components/CardResumoPrestamista';
import { Card, Text, Display, Button } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { usePecoEmitirCertificado } from '../hooks/usePecoEmitirCertificado';
import { IResponseEmitirCertificado } from 'prestamista/types/EmissaoCertificado';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useHistory } from 'react-router';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { AppContext } from 'main/contexts/AppContext';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { TipoSeguro } from 'seguros/types/DadosSegurado';

const EmissaoCertificado: React.FC = () => {
  const history = useHistory();
  const { clientePrestamista } = useContext(AppContext);
  const { emitirCertificado, loading, response } = usePecoEmitirCertificado(
    tryGetValueOrDefault([clientePrestamista.numeroContrato], ''),
  );

  const objetoEmail = {
    tipoEmail: 'SegundaViaCertificado',
    parametrosEnvio: {
      NumeroContrato: Number(clientePrestamista.numeroContrato),
    },
  };

  const gerarPdf = () => {
    const [retornoServico] =
      response?.entidade ?? ([] as IResponseEmitirCertificado[]);
    emitirCertificado(retornoServico?.conteudoBase64);
  };
  return (
    <>
      <CardResumoPrestamista />

      <Display type="display-block">
        <RenderConditional condition={loading}>
          <SkeletonLoading lines={4} />
        </RenderConditional>
        <RenderConditional condition={!loading}>
          <Card>
            <Card.Content padding={[6, 8, 6, 8]}>
              <Text variant="headline-05" color="primary" margin>
                Emissão de Certificado
              </Text>

              <Text variant="body02-sm" margin>
                Você pode visualizar o certificado:
              </Text>

              <Display>
                <Button
                  variant="outlined"
                  onClick={() => history.goBack()}
                  data-testid="btnVoltar"
                >
                  Voltar
                </Button>
                <Button
                  variant="secondary"
                  onClick={gerarPdf}
                  loading={loading}
                  disabled={loading}
                >
                  Emitir
                </Button>
                <EmailSenderModal
                  objetoEmail={objetoEmail}
                  seguro={TipoSeguro.PRESTAMISTA}
                />
              </Display>
            </Card.Content>
          </Card>
        </RenderConditional>
      </Display>
    </>
  );
};

export default EmissaoCertificado;
