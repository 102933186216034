import React, { useEffect, useState } from 'react';
import Table from 'main/components/Table';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { formatarData } from 'main/utils';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import {
  COLUNAS_HISTORICO_SOLICITACOES,
  FLAGS_FOPS,
} from 'previdencia/constants/constants';
import { TabelaMovimentacoesProps } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoesProps';
import useComprovanteResgate from 'previdencia/features/HistoricoSolicitacoes/hooks/useComprovanteResgate';
import ButtonAcoes from 'previdencia/features/HistoricoSolicitacoes/components/TabelaMovimentacoes/ButtonAcoes';
import { converterBase64 } from 'main/utils/converterBase64';
import { ModalCancelarTransferencia } from '../ModalCancelarTransferencia/ModalCancelarTransferencia';

const TabelaMovimentacoes: React.FC<TabelaMovimentacoesProps> = ({
  dados,
  loading,
}) => {
  const [codRequisicao, setCodRequisicao] = useState('');
  const [idRequisicaoRequest, setIdRequisicaoRequest] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { data, obterComprovanteHistorico } = useComprovanteResgate(
    codRequisicao,
    idRequisicaoRequest,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idRequisicao, setIdRequisicao] = useState('');

  const fecharModalCancelar = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const abrirModalCancelar = (numeroSolicitacao: string) => {
    setIsModalOpen(true);
    setIdRequisicao(numeroSolicitacao);
  };

  useEffect(() => {
    if (codRequisicao && idRequisicaoRequest) {
      setIsFetching(true);
      obterComprovanteHistorico();
    }
  }, [codRequisicao, idRequisicaoRequest]);

  useEffect(() => {
    if (data) {
      const blob = converterBase64(data.comprovante);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      setIsFetching(false);
    }
  }, [data]);

  const dadosHistoricoSolicitacoes = () => {
    return dados?.map(item => ({
      realizado: formatarData(item.dataRequisicao),
      canal: item.canalRequisicao,
      comprovante: (
        <ButtonAcoes
          onClose={() => abrirModalCancelar(item.idRequisicao)}
          idRequisicao={item.idRequisicao}
          tipoRequisicao={getTernaryResult(
            item.codigoRequisicao.includes(FLAGS_FOPS.FOP223),
            `${item.tipoRequisicao}${FLAGS_FOPS.FOP223}`,
            item.tipoRequisicao,
          )}
          statusRequisicao={item.statusRequisicao}
          permiteCancelamento={item.permiteCancelamento}
          isFetching={isFetching}
          idRequisicaoRequest={idRequisicaoRequest}
          setIdRequisicaoRequest={setIdRequisicaoRequest}
          setCodRequisicao={setCodRequisicao}
        />
      ),
      servico: getTernaryResult(
        item.codigoRequisicao.includes(FLAGS_FOPS.FOP223),
        item.codigoRequisicao.replace(FLAGS_FOPS.FOP223, ''),
        item.codigoRequisicao,
      ),
      status: item.statusRequisicao,
      valor: formatarValorPadraoBrasileiro(item.valorRequisicao),
    }));
  };

  if (loading) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <ModalCancelarTransferencia
        isOpen={isModalOpen}
        onClose={fecharModalCancelar}
        idRequisicao={idRequisicao}
      />

      <Table
        noHeader
        responsive
        highlightOnHover
        data={tryGetValueOrDefault([dadosHistoricoSolicitacoes()], [])}
        columns={COLUNAS_HISTORICO_SOLICITACOES}
        noDataComponent="Não há dados para exibir."
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{
          rowsPerPageText: 'Itens por página',
          rangeSeparatorText: 'de',
        }}
      />
    </>
  );
};

export default TabelaMovimentacoes;
