import { IDataTableColumn } from 'react-data-table-component';
import { IAssistenciaPorContrato } from '../types/ICoberturas';

export const COLUNAS_TABELA_ASSISTENCIAS: IDataTableColumn<IAssistenciaPorContrato>[] =
  [
    {
      name: 'Nome Acoplado',
      selector: row => row.nomeAcoplado,
    },
  ];

export const SEM_DADOS_ASSISTENCIAS =
  'Não há dados de assistência a serem exibidos.';
