import { Button, Display } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import TableFilter from 'main/components/Table/TableFilter/TableFilter';
import * as S from 'main/components/Table/customStyles';
import { TableSkeleton } from 'portabilidade/components/Table';
import useRelatorioResgateFilterOptions from 'previdencia/features/Resgate/hooks/useRelatorioResgateFilterOptions';
import * as RESPONSE_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateResponse';
import React, { useCallback } from 'react';
import { Container } from './styles';
import {
  COLUNA_RELATORIO_RESGATE,
  TAGS_FILTER_DESCRIPTION,
} from 'previdencia/features/Resgate/constants/constants';

interface IListagemResgatesProps {
  listaResgates?: RESPONSE_TYPES.IRelatorioResgateResponse[];
  loading: boolean;
  voltar: () => void;
  exibirDetalhe: (data: RESPONSE_TYPES.IRelatorioResgateResponse) => void;
}

const ListaResgates: React.FC<IListagemResgatesProps> = ({
  listaResgates,
  loading,
  voltar,
  exibirDetalhe,
}) => {
  const filterOptions: IFilterOption[] =
    useRelatorioResgateFilterOptions(listaResgates);

  const imprimir = useCallback(() => {
    window.print();
  }, []);

  return (
    <Container>
      <RenderConditional condition={loading}>
        <TableSkeleton colunas={COLUNA_RELATORIO_RESGATE(exibirDetalhe)} />
      </RenderConditional>
      <RenderConditional condition={!loading}>
        <TableFilter
          filterOptions={filterOptions}
          dataToFilter={listaResgates}
          filterTextPartial
          tagsFilterDescription={TAGS_FILTER_DESCRIPTION}
          customButton={
            <Button
              type="button"
              onClick={voltar}
              variant="outlined"
              data-testid="voltar"
            >
              Voltar
            </Button>
          }
        >
          {dataFiltered => (
            <>
              <div className="hide-print">
                <S.TableListaResgates
                  noHeader
                  responsive
                  data={
                    dataFiltered as RESPONSE_TYPES.IRelatorioResgateResponse[]
                  }
                  columns={COLUNA_RELATORIO_RESGATE(exibirDetalhe)}
                  noDataComponent="Não há dados para exibir."
                  pagination
                  paginationPerPage={10}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Items por página',
                    rangeSeparatorText: 'de',
                  }}
                />
              </div>

              <S.TableListaResgates
                className="show-print"
                noHeader
                responsive
                data={
                  dataFiltered as RESPONSE_TYPES.IRelatorioResgateResponse[]
                }
                columns={COLUNA_RELATORIO_RESGATE(exibirDetalhe)}
                noDataComponent="Não há dados para exibir."
              />

              <RenderConditional condition={dataFiltered.length > 0}>
                <Display>
                  <Button
                    className="hide-print"
                    variant="primary"
                    onClick={imprimir}
                  >
                    Imprimir
                  </Button>
                </Display>
              </RenderConditional>
            </>
          )}
        </TableFilter>
      </RenderConditional>
    </Container>
  );
};

export default ListaResgates;
