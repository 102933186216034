import { Button } from '@cvp/design-system/react';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import { formatarData } from 'main/utils';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import * as RESPONSE_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateResponse';

export const TAGS_FILTER_DESCRIPTION =
  'Você também tem a opção de filtrar por tipo de resgate, escolha abaixo e clique em filtrar.';

export const COLUNA_RELATORIO_RESGATE = (
  exibirDetalhe: (data: RESPONSE_TYPES.IRelatorioResgateResponse) => void,
) => [
  {
    name: 'Cód. Certificado',
    selector: 'codigoCertificado',
    sortable: true,
    cell: (data: RESPONSE_TYPES.IRelatorioResgateResponse) => (
      <Button
        data-testid="btnCodigoCertificado"
        style={{ padding: 0, textAlign: 'left' }}
        variant="text"
        onClick={() => exibirDetalhe(data)}
      >
        {data.codigoCertificado}
      </Button>
    ),
  },
  {
    name: 'Agência',
    selector: 'codigoAgencia',
    sortable: true,
  },
  {
    name: 'CPF/CNPJ',
    selector: 'cpfCnpjCliente',
    sortable: true,
    cell: ({ cpfCnpjCliente }: RESPONSE_TYPES.IRelatorioResgateResponse) => (
      <span>{formatarCpf(cpfCnpjCliente)}</span>
    ),
  },
  {
    name: 'Cliente',
    selector: 'nomeCliente',
    sortable: true,
  },
  {
    name: 'Gerado em',
    selector: 'dataGeracao',
    sortable: true,
    cell: ({ dataGeracao }: RESPONSE_TYPES.IRelatorioResgateResponse) => (
      <span>{formatarData(dataGeracao)}</span>
    ),
  },
  {
    name: 'Tipo de Resgate',
    selector: 'tipoResgate',
    sortable: true,
  },
  {
    name: 'Valor do Resgate',
    selector: 'valorResgate',
    sortable: true,
    cell: ({ valorResgate }: RESPONSE_TYPES.IRelatorioResgateResponse) => (
      <span>{formatarValorPadraoBrasileiro(valorResgate)}</span>
    ),
  },
];
