import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { formatarData } from 'main/utils';
import masks from 'main/utils/masks';
import {
  IStatusPropostaDpsAgrupado,
  IStatusPropostaDpsAgrupadoTabela,
} from 'contratosPrestamista/features/dpsEletronicaPostecipado/types/IResponseListarStatusPropostaDps';
import {
  STATUS_DPS,
  STATUS_UPLOAD,
} from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/MapeamentoStatus';
import * as S from 'contratosPrestamista/features/dpsEletronicaPostecipado/components/styles';
import { formatarDataUpload } from 'contratosPrestamista/utils/formatarDataUpload';
import { IDataTableColumn } from 'react-data-table-component';

const renderProposta = (row: IStatusPropostaDpsAgrupado) => (
  <S.LinkedTable
    to={{
      pathname: `/prestamista/dps/status/consulta/resultado/detalhes`,
      state: { ...row },
    }}
  >
    {row.propostaEstip}
  </S.LinkedTable>
);

const renderStatusDps = (row: IStatusPropostaDpsAgrupado) => {
  if (row.tipoPessoa === 'J') return '-';
  return (
    <S.TextWithEllipsis title={STATUS_DPS[row.statusDps]}>
      {STATUS_DPS[row.statusDps]}
    </S.TextWithEllipsis>
  );
};

const renderStatusProposta = (row: IStatusPropostaDpsAgrupado) => (
  <S.TextWithEllipsis title={STATUS_UPLOAD[row.statusDocumento]}>
    {STATUS_UPLOAD[row.statusDocumento]}
  </S.TextWithEllipsis>
);

const renderDataUpload = (row: IStatusPropostaDpsAgrupadoTabela) => (
  <span>{formatarDataUpload(row.dataUpload)}</span>
);

export const ColunasListaStatusPropostaDps: IDataTableColumn<IStatusPropostaDpsAgrupadoTabela>[] =
  [
    {
      name: 'Proposta',
      selector: row => row.propostaEstip,
      sortable: true,
      minWidth: '165px',
      cell: renderProposta,
    },
    {
      name: 'Data da proposta',
      center: true,
      sortable: true,
      selector: row => row.dataProposta,
      cell: row => <span>{formatarData(row.dataProposta)}</span>,
      width: '140px',
    },
    {
      name: 'CPF/CNPJ',
      selector: row => row.numCpfCnpj,
      center: true,
      sortable: true,
      width: '150px',
      cell: row => <span>{maskCpfCnpj(row.numCpfCnpjFormatado)}</span>,
    },
    {
      name: 'Agência',
      selector: row => row.codigoUnidadeVenda,
      center: true,
      sortable: true,
      width: '90px',
    },
    {
      name: 'Valor Prêmio',
      selector: row => row.valorPremio,
      minWidth: '110px',
      center: true,
      sortable: true,
      cell: row => <span>{masks.currency.mask(row.valorPremio)}</span>,
    },
    {
      name: 'Status DPS',
      selector: row => row.statusDps,
      minWidth: '120px',
      sortable: true,
      cell: renderStatusDps,
    },
    {
      name: 'Status Proposta',
      selector: row => row.dataUpload,
      width: '170px',
      sortable: true,
      cell: renderStatusProposta,
    },
    {
      name: 'Data Upload',
      selector: row => row.statusDocumento,
      width: '140px',
      center: true,
      sortable: true,
      cell: renderDataUpload,
    },
  ];

export const ColunasListaStatusPropostaDpsAgrupado = () => [
  {
    name: 'CPF Sócio',
    selector: (row: IStatusPropostaDpsAgrupado) => row.numCpfSocioFormatado,
    sortable: true,
    width: '150px',
    cell: (row: IStatusPropostaDpsAgrupado) => (
      <span>{maskCpfCnpj(row.numCpfSocioFormatado)}</span>
    ),
  },
  {
    name: 'Status DPS',
    selector: (row: IStatusPropostaDpsAgrupado) => row.statusDps,
    width: '250px',
    sortable: true,
    cell: (row: IStatusPropostaDpsAgrupado) => (
      <S.TextWithEllipsis title={STATUS_DPS[row.statusDps]}>
        {STATUS_DPS[row.statusDps]}
      </S.TextWithEllipsis>
    ),
  },
];
