import React, { useEffect, useState } from 'react';
import { Card, Text, Divider, Button } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { Display } from 'main/features/Auth/components';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import * as CONSTS_BENEFICIARIOS from 'seguros/constants/DadosBeneficiarios';
import * as FACTORY from 'seguros/factories/DadosBeneficiariosFactory';
import * as DADOS_BENEFICIARIOS_TYPES from 'seguros/types/DadosBeneficiarios';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { TipoSeguro } from 'seguros/types/DadosSegurado';

export const DadosBeneficiarios: React.FC<
  DADOS_BENEFICIARIOS_TYPES.DadosBeneficiariosProps
> = ({ data, loading }) => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  const [showPagination, setShowPagination] = useState(true);

  const dadosCertificado = data?.entidade;

  const commonTableProps = FACTORY.commonTablePropsFactory({
    pagination: showPagination,
  });

  const exibirTabelaBeneficiarioIndividual: boolean = checkIfAllItemsAreTrue([
    !loading,
    dadosCertificado?.tipoContrato ===
      DADOS_BENEFICIARIOS_TYPES.TipoContrato.INDIVIDUAL,
  ]);

  const emailDefault = dadosCertificado?.dadosComunsBilheteEmpresarial?.email;
  const objetoEmail = {
    tipoEmail: tipoEmailConstants.INFORMACOES_BENEFICIARIO,
    parametrosEnvio: {
      numeroCertificadoBilhete: String(
        dadosCertificado?.dadosGeraisCertificado?.numeroCertificado,
      ),
      codigoProduto: String(
        dadosCertificado?.dadosGeraisCertificado?.codigoProduto,
      ),
    },
  };

  const exibirTabelaBeneficiarioBilhete: boolean = checkIfAllItemsAreTrue([
    !loading,
    checkIfSomeItemsAreTrue([
      dadosCertificado?.tipoContrato ===
        DADOS_BENEFICIARIOS_TYPES.TipoContrato.BILHETE,
      dadosCertificado?.tipoContrato ===
        DADOS_BENEFICIARIOS_TYPES.TipoContrato.EMPRESARIAL,
    ]),
  ]);

  const executePrint = (): void => {
    if (!showPagination) {
      window.print();
      setShowPagination(true);
    }
  };

  const handleImprimir = (): void => {
    setShowPagination(false);
  };

  const validaBeneficiarioDadosBilhete = () => {
    return dadosCertificado?.bilhete?.dadosBeneficiarioBilheteVida
      ? [dadosCertificado?.bilhete?.dadosBeneficiarioBilheteVida]
      : [];
  };

  useEffect(executePrint, [showPagination]);

  return (
    <>
      <Card>
        <Card.Content>
          <Text
            variant="headline-05"
            color="primary"
            margin
            data-testid="dadosBeneficiariosTitulo"
          >
            {CONSTS_BENEFICIARIOS.TEXTOS_DADOS_BENEFICIARIOS.TITULO}
          </Text>
          <Text
            variant="caption-02"
            color="text-light"
            margin
            data-testid="dadosBeneficiariosSubtitulo"
          >
            {CONSTS_BENEFICIARIOS.TEXTOS_DADOS_BENEFICIARIOS.SUBTITULO}
          </Text>
          <div data-testid="hide-print">
            <RenderConditional condition={loading}>
              <SkeletonLoading blocks={1} />
            </RenderConditional>
            <RenderConditional condition={exibirTabelaBeneficiarioIndividual}>
              <Table
                {...commonTableProps}
                columns={CONSTS.COLUNAS_TABELA_BENEFICIARIOS_INDIVIDUAL}
                data={tryGetValueOrDefault(
                  [dadosCertificado?.individual?.beneficiarios],
                  [],
                )}
              />
            </RenderConditional>
            <RenderConditional condition={exibirTabelaBeneficiarioBilhete}>
              <Table
                {...commonTableProps}
                columns={CONSTS.COLUNAS_TABELA_BENEFICIARIOS_BILHETE}
                data={validaBeneficiarioDadosBilhete()}
              />
            </RenderConditional>
          </div>
          <Divider />
          <br />
          <Display>
            <Button
              onClick={goDadosSeguradoVida}
              variant="outlined"
              data-testid="voltar"
              className="hide-print"
            >
              Voltar
            </Button>
            <Button
              className="hide-print"
              variant="secondary"
              onClick={handleImprimir}
              data-testid="print-button"
            >
              Imprimir
            </Button>
            <EmailSenderModal
              objetoEmail={objetoEmail}
              defaultEmail={emailDefault}
              seguro={TipoSeguro.VIDA}
            />
          </Display>
        </Card.Content>
      </Card>
    </>
  );
};
