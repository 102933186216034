import React from 'react';
import { Card, Button, Text, Tooltip } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import Icon from 'main/components/Icon';
import {
  TextWarningWithEllipsis,
  TextWithEllipsis,
} from 'main/styles/GlobalStyle';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import TableFilter from 'main/components/Table/TableFilter/TableFilter';
import {
  FILTER_STATUS_BY_PROFILE,
  STATUS_PROSPECCAO,
} from '../constants/statusProspeccao';
import { DisplayTooltip, TooltipStatus } from './styles';
import { IDataTableColumn } from 'react-data-table-component';

const buildColumns = (
  onclick: (value: IProspeccao) => void,
): IDataTableColumn<IProspeccao>[] => [
  {
    name: 'Nº',
    selector: row => row.id,
    width: '60px',
    cell: proposta => (
      <Button
        style={{ padding: 0, textAlign: 'left' }}
        variant="text"
        onClick={() => onclick(proposta)}
      >
        {proposta.id}
      </Button>
    ),
  },
  {
    name: 'Cliente',
    selector: row => row.nomeCliente,
    width: '220px',
    sortable: true,
    cell: ({ nomeCliente }) => (
      <TextWithEllipsis title={nomeCliente}>{nomeCliente}</TextWithEllipsis>
    ),
  },
  {
    name: 'CPF',
    selector: row => row.cpfCnpj,
    sortable: true,
    width: '150px',
  },
  {
    name: 'Valor',
    selector: row => row.valorProspeccaoFormatado,
    sortable: true,
    width: '180px',
  },
  {
    name: 'Agência',
    sortable: true,
    width: '70px',
    selector: row => row.numeroAgencia,
  },
  {
    name: 'Data',
    selector: row => row.dataHoraCadastroFormatada,
    sortable: true,
    width: '130px',
  },
  {
    name: 'Status',
    selector: row => row.situacao.descricao,
    sortable: true,
    cell: ({ situacao, codigoTipoSituacaoVigente }) => {
      if (codigoTipoSituacaoVigente === STATUS_PROSPECCAO.PRE_CADASTRADA) {
        return (
          <DisplayTooltip>
            <TextWarningWithEllipsis warning title={situacao.descricao}>
              {situacao.descricao}
            </TextWarningWithEllipsis>
            <Tooltip
              text="Upload pendente! Anexe o arquivo da DPS na página de detalhes da Pré-análise."
              position="left"
              variant="primary"
            >
              <TooltipStatus>
                <Icon name="warning" />
              </TooltipStatus>
            </Tooltip>
          </DisplayTooltip>
        );
      }

      return (
        <TextWithEllipsis title={situacao.descricao}>
          {situacao.descricao}
        </TextWithEllipsis>
      );
    },
  },
];

type ListaPreAnaliseProps = {
  data: IProspeccao[];
  onClickDetails: (value: IProspeccao) => void;
  title?: string;
  noDataMessage?: string;
  profile: string;
  children?: React.ReactNode;
};

export const ListaPreAnalise: React.FC<ListaPreAnaliseProps> = ({
  data,
  children,
  title,
  onClickDetails,
  noDataMessage = 'Não há pré-análises cadastradas para o cliente',
  profile,
}) => {
  const filterOptions: IFilterOption[] = [
    { key: 'numeroAgencia', value: 'Agência', type: FilterTypes.TEXT },
    { key: 'nomeCliente', value: 'Nome do cliente', type: FilterTypes.TEXT },
    { key: 'cpfCnpj', value: 'CPF', type: FilterTypes.TEXT },
    { key: 'dataHoraCadastro', value: 'Data', type: FilterTypes.DATE },
    {
      key: 'codigoTipoSituacaoVigente',
      value: 'Status',
      type: FilterTypes.TAG,
      checkboxConfig: {
        options: FILTER_STATUS_BY_PROFILE[profile],
      },
    },
  ];

  return (
    <>
      <Card style={{ minHeight: 'auto' }}>
        <Card.Content>
          {title && (
            <Text variant="headline-05" color="primary" margin>
              {title}
            </Text>
          )}

          <TableFilter
            dataToFilter={data}
            filterTextPartial
            filterOptions={filterOptions}
          >
            {filteredData => (
              <div style={{ position: 'relative' }}>
                <div style={{ overflow: 'auto' }}>
                  <Table
                    noHeader
                    columns={buildColumns(onClickDetails)}
                    data={filteredData}
                    responsive
                    pagination
                    paginationPerPage={10}
                    noDataComponent={noDataMessage}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Items por página',
                      rangeSeparatorText: 'de',
                    }}
                  />
                </div>
              </div>
            )}
          </TableFilter>
        </Card.Content>
      </Card>
      {children || null}
    </>
  );
};
