import { ResumoPortabilidade } from 'portabilidade/types';
import { IDataTableColumn } from 'react-data-table-component';

export type AcaoRetencao = {
  ID_ACAO: string;
  MENSAGEM: string;
};

const MENSAGEM_SOLICITAR_PRORROGACAO =
  'Tem certeza que deseja solicitar a prorrogação da portabilidade?';
const MENSAGEM_SOLICITAR_RETENCAO =
  'Tem certeza que deseja reter a portabilidade?';

export const ACAO_RETENCAO = {
  SOLICITAR_PRORROGACAO: {
    ID_ACAO: 'PRORROGACAO',
    MENSAGEM_CONFIRMACAO: MENSAGEM_SOLICITAR_PRORROGACAO,
  },
  SOLICITAR_CANCELAMENTO: {
    ID_ACAO: 'CANCELAMENTO',
    MENSAGEM_CONFIRMACAO: MENSAGEM_SOLICITAR_RETENCAO,
  },
  SOLICITAR_PRORROGACAO_VIA_EMAIL: {
    ID_ACAO: '1',
    MENSAGEM_CONFIRMACAO: MENSAGEM_SOLICITAR_PRORROGACAO,
  },
  SOLICITAR_CANCELAMENTO_VIA_EMAIL: {
    ID_ACAO: '2',
    MENSAGEM_CONFIRMACAO: MENSAGEM_SOLICITAR_RETENCAO,
  },
  SOLICITAR_PRORROGACAO_VIA_SMS: {
    ID_ACAO: '6',
    MENSAGEM_CONFIRMACAO: MENSAGEM_SOLICITAR_PRORROGACAO,
  },
  SOLICITAR_CANCELAMENTO_VIA_SMS: {
    ID_ACAO: '7',
    MENSAGEM_CONFIRMACAO: MENSAGEM_SOLICITAR_RETENCAO,
  },
  ATRIBUIR_USUARIO: {
    ID_ACAO: '3',
    MENSAGEM_CONFIRMACAO: 'Tem certeza que deseja atender esta portabilidade?',
  },
  ALTERAR_DADOS_CLIENTE: {
    ID_ACAO: '4',
  },
  LIBERAR_ATENDIMENTO: {
    ID_ACAO: '5',
    MENSAGEM_CONFIRMACAO:
      'Tem certeza que deseja liberar esta portabilidade para atendimento?',
  },
  OPCAO_ASSINAR_PROPOSTA: {
    ID_ACAO: 'ASSINAR_PROPOSTA',
    MENSAGEM_CONFIRMACAO: '',
  },
  ASSINATURA_PRORROGACAO_SOLICITADA: {
    ID_ACAO: '8',
    MENSAGEM_CONFIRMACAO: '',
  },
  ASSINATURA_CANCELAMENTO_SOLICITADA: {
    ID_ACAO: '9',
    MENSAGEM_CONFIRMACAO: '',
  },
  CANCELAR_PROCESSO_ASSINATURA: {
    ID_ACAO: '10',
    MENSAGEM_CONFIRMACAO: '',
  },
  RETENCAO_NAO_ACEITA_PELO_CLIENTE: {
    ID_ACAO: '11',
    MENSAGEM_CONFIRMACAO:
      'Tem certeza que deseja cancelar a retenção deste cliente? Esta ação inviabilizará qualquer tentativa de retenção ou prorrogação desta portabilidade.',
  },
};

export const TIPO_ASSINATURA = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

export const STATUS_PORTABILIDADE = {
  RECEBIDA: 3,
  ANDAMENTO: 2,
  PRORROGADA: 1,
  CANCELAMENTO_CONFIRMADO: 8,
  NAO_RETIDA: 9,
  RETENCAO_NAO_ACEITA: 12,
};

export const COLUNAS_TABELA_RETENCAO: IDataTableColumn<ResumoPortabilidade>[] =
  [
    {
      name: 'Agência',
      selector: row => row.numeroAgenciaOrigem,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Valor da portabilidade',
      selector: row => row.valorPortabilidade,
      sortable: true,
      width: '180px',
    },
    {
      name: 'Vence em ',
      selector: row => row.dataVencimento,
      sortable: true,
    },
    {
      name: 'Responsável',
      selector: (row: any) =>
        row.usuarioResponsavel ? row.usuarioResponsavel.nomeUsuario : '',
      sortable: true,
    },
  ];

export const CHAVE_ID_DETALHE_PORTABILIDADE_ATUAL =
  '@portal-eco:portabilidade-id';
export const CHAVE_ID_ACAO_RETENCAO_EXECUTADA = '@portal-eco:id-acao-executada';
