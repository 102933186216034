import React from 'react';
import { Accordion } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { Table } from 'main/components/Table';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TabelaApoliceSorteiosProps } from 'seguros/types/ApoliseCobertura';
import * as CONSTS from 'seguros/constants/TabelaApoliceSorteios';

export const TabelaApoliceSorteios: React.FC<TabelaApoliceSorteiosProps> = ({
  data,
  columns,
}) => {
  return (
    <RenderConditional condition={!!data.length}>
      <Accordion open className="hide-print">
        <Accordion.Item title="Sorteios" key="sorteios">
          <Table
            noHeader
            responsive
            striped
            columns={tryGetValueOrDefault(
              [columns],
              CONSTS.COLUNAS_TABELA_SORTEIOS,
            )}
            data={tryGetValueOrDefault([data], [])}
            noDataComponent={CONSTS.SEM_DADOS}
          />
        </Accordion.Item>
      </Accordion>
    </RenderConditional>
  );
};

export default TabelaApoliceSorteios;
