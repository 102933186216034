import { ICarencia } from 'seguros/types/IResponseCertificadosResumo';

export const COLUNAS_TABELA_CARENCIAS = [
  {
    name: '',
    selector: (row: ICarencia) => row.descricao,
  },
];

export const SEM_DADOS_CARENCIAS = 'Não há dados de carência a serem exibidos.';
