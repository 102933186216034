// @ts-nocheck
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Display,
  Divider,
  Grid,
  Text,
} from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { converterBase64 } from 'main/utils/converterBase64';
import EmailSenderModal from 'main/components/EmailSenderModal';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useDeclaracaoQuitacaoAnual from 'previdencia/features/DeclaracaoQuitacaoAnual/hooks/useDeclaracaoQuitacaoAnual';
import { quitacaoAnualDate } from 'previdencia/features/DeclaracaoQuitacaoAnual/utils/quitacaoAnualDate';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as S from './styles';
import {
  DISPOSTO_LEI_COBERTURA,
  DOCUMENTO_QUITACAO_ANUAL,
  LEI_PESSOA_JURIDICA,
  SEM_DADOS_FILTRO_ANO,
} from '../constants/constants';

const DeclaracaoQuitacaoAnual: React.FC = () => {
  const { goDadosPlano } = usePrevNavigation();

  const [anoConsulta, setAnoConsulta] = useState('');
  const { cliente } = useContext(AppContext);
  const { data, refetch, isSuccess, isRefetchError, isFetching } =
    useDeclaracaoQuitacaoAnual(anoConsulta);

  const isSucessDeclaracaoQuitacaoAnual = useCallback(() => {
    return isSuccess && !isFetching && !isRefetchError && data;
  }, [isSuccess, isFetching, isRefetchError, data]);

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.QUITACAO_ANUAL_DEBITOS,
    parametrosEnvio: {
      cpfCnpj: cliente?.cpfCnpj,
      NumeroCertificado: cliente.numCertificado,
      Ano: anoConsulta,
    },
  };

  useEffect(() => {
    if (anoConsulta !== '') {
      refetch();
    }
  }, [anoConsulta]);

  useEffect(() => {
    if (isSucessDeclaracaoQuitacaoAnual()) {
      const blob = converterBase64(data);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }, [isSucessDeclaracaoQuitacaoAnual, data]);

  function deveMostrarTextoSemDadosAnoSelecionado() {
    return !isFetching && anoConsulta.length > 0;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[PREV_PERMISSIONS.QUITACAO_ANUAL]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
          USER_PROFILES.ANALISTA_PJ,
        ]}
      >
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Declaração de Quitação Anual de Débitos
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body02-sm" color="text" margin>
                  {DISPOSTO_LEI_COBERTURA}
                </Text>
                <Text variant="body02-sm" color="text" margin>
                  {DOCUMENTO_QUITACAO_ANUAL}
                </Text>
                <S.TextItalic>{LEI_PESSOA_JURIDICA}</S.TextItalic>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Grid.Item xs={1}>
                  <Text variant="body02-sm" color="text">
                    Informe o Ano de Quitação:
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 3} md={1}>
                  {quitacaoAnualDate().map(e => {
                    return (
                      <S.ButtonAnoConsulta
                        key={e}
                        variant={e === anoConsulta ? 'secondary' : 'outlined'}
                        disabled={isFetching && e !== anoConsulta}
                        loading={isFetching && e === anoConsulta}
                        onClick={() => setAnoConsulta(e)}
                      >
                        {e}
                      </S.ButtonAnoConsulta>
                    );
                  })}
                  <RenderConditional
                    condition={deveMostrarTextoSemDadosAnoSelecionado()}
                  >
                    <Text variant="body02-sm" color="text">
                      {SEM_DADOS_FILTRO_ANO}
                    </Text>
                  </RenderConditional>
                  <Divider />
                </Grid.Item>
                <Display>
                  <Button variant="outlined" onClick={goDadosPlano}>
                    Voltar
                  </Button>
                  <EmailSenderModal objetoEmail={objetoEmail} />
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default DeclaracaoQuitacaoAnual;
