import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { Display } from 'main/features/Auth/components';
import TableFilters from 'main/components/Table/TableFilter/TableFilter';
import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import { usePecoListarStatusProposta } from 'contratosPrestamista/features/dpsEletronicaPostecipado/hooks/usePecoListarStatusProposta';
import {
  ColunasListaStatusPropostaDps,
  ColunasListaStatusPropostaDpsAgrupado,
} from '../constants/ColunasListaStatusPropostaDps';
import {
  IResponseListarStatusPropostaDps,
  IStatusPropostaDpsAgrupado,
} from 'contratosPrestamista/features/dpsEletronicaPostecipado/types/IResponseListarStatusPropostaDps';
import * as S from 'contratosPrestamista/features/dpsEletronicaPostecipado/components/styles';
import { IRequestListarStatusProposta } from 'contratosPrestamista/features/dpsEletronicaPostecipado/types/IRequestListarStatusProposta';
import { TIPO_PESSOA_DPS } from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/MapeamentoStatus';

type ExpandedComponentProp = {
  data: IStatusPropostaDpsAgrupado;
};

const ListaStatusPropostaDps: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<IRequestListarStatusProposta>();
  const { setFeatureData } =
    useContratosPrestamistaContext<IRequestListarStatusProposta>();

  const filterOptions: IFilterOption[] = [
    {
      key: 'numCpfCnpjFormatado',
      value: 'CPF/CNPJ',
      type: 'text',
      unmask: masks.numberOnly.mask,
    },
    { key: 'propostaEstip', value: 'Proposta', type: 'text' },
    {
      key: 'dataProposta',
      value: 'Data da Proposta',
      type: 'date',
    },
  ];

  const colunas = useMemo(() => ColunasListaStatusPropostaDps, []);
  const colunasSocio = useMemo(
    () => ColunasListaStatusPropostaDpsAgrupado(),
    [],
  );
  const listarStatusPropostaservice = usePecoListarStatusProposta();

  useEffect(() => {
    listarStatusPropostaservice.obterDadosETransformarRetorno(state);
    if (state) {
      setFeatureData(state);
    }
  }, []);

  if (listarStatusPropostaservice.loadingListarStatusProposta)
    return <SkeletonLoading />;

  const ExpandedComponent = (row: unknown) => {
    const { data } = row as ExpandedComponentProp;

    const { socios } = data;
    return (
      <Display style={{ padding: '0 15px', width: '90%', margin: '0 auto' }}>
        <Table
          columns={colunasSocio}
          data={tryGetValueOrDefault([socios], [])}
        />
      </Display>
    );
  };

  return (
    <>
      <TableFilters
        dataToFilter={listarStatusPropostaservice.resultadoAgrupado}
        filterOptions={filterOptions}
        filterTextPartial
        customButton={
          <Button
            onClick={() => history.push('/prestamista/dps/status/consulta')}
          >
            Nova Consulta
          </Button>
        }
      >
        {dataFiltered => (
          <>
            <S.Table
              highlightOnHover
              striped
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              paginationComponentOptions={{
                rowsPerPageText: 'Items por página',
                rangeSeparatorText: 'de',
              }}
              columns={colunas}
              data={tryGetValueOrDefault([dataFiltered], [])}
              expandableRows
              expandableRowDisabled={(row: {
                tipoPessoa: string;
                socios: IResponseListarStatusPropostaDps[];
              }) =>
                row.tipoPessoa === TIPO_PESSOA_DPS.FISICA ||
                tryGetValueOrDefault([row.socios], []).length === 0
              }
              expandableRowsComponent={<ExpandedComponent />}
              noDataComponent="Não há dados para exibir."
            />
          </>
        )}
      </TableFilters>
    </>
  );
};

export default ListaStatusPropostaDps;
