// @ts-nocheck
import { FormikProps } from 'formik';
import { CommonMasks } from 'main/utils';

export type DynamicInfoInsured = {
  cpfCnpj: string;
  seguradoPF: string;
  seguradoPJ: string;
};

export interface IFormikValues {
  cep: string;
  endereco: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  ddd: string;
  email: string;
  dadosPessoaisOutrosProdutos: boolean;
  dadosPessoaisParceiros: boolean;
  telefone: string;
  celular: string;
  ocorrencia?: string;
  nomeSocial: string;
  utilizarNomeSocial: boolean;
}

export type MsgInputFormikError = {
  [key: string]: string;
};

export type DadosSeguradoFormProps = {
  formik: FormikProps<IFormikValues>;
  handleCepChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  masks: CommonMasks;
  loadingDadosConsentimentoAtualizadosProdutosCaixa: boolean;
  loadingDadosConsentimentoAtualizadosParceiros: boolean;
  loadingDadosSeguradoAtualizados: boolean;
  cpfCnpj: string;
  nomeSegurado: string | undefined;
  dataNascimentoPorTipoCertificado: string;
};

export enum TipoSeguro {
  VIDA = 'vida',
  PRESTAMISTA = 'prestamista',
}
