import { IDataTableColumn } from 'react-data-table-component';
import { ResponsePendenteDevolucao } from '../types/ResponsePendenteDevolucao';

export const COLUNAS_TABELA_RECURSOS_PENDENTES_DEVOLUCAO: IDataTableColumn<ResponsePendenteDevolucao>[] =
  [
    {
      name: 'Nº Certificado',
      selector: row => row.numeroProposta,
      center: true,
      minWidth: '160px',
    },
    {
      name: 'Nome',
      selector: row => row.dadosPessoa[0].nome,
      sortable: true,
      minWidth: '180px',
      wrap: true,
    },
    {
      name: 'CPF / CNPJ',
      selector: row => row.dadosPessoa[0].cpf,
      center: true,
      minWidth: '150px',
    },
    {
      name: 'Agência',
      selector: row => row.agencia,
      center: true,
      minWidth: '50px',
    },
    {
      name: 'Valor a receber',
      selector: row => row.valorReceber,
      center: true,
      minWidth: '140px',
    },
    {
      name: 'Prazo de regularização',
      selector: row => row.prazoRegularizacao,
      center: true,
      minWidth: '150px',
    },
    {
      name: 'Transação de origem',
      selector: row => row.transacaoOrigem,
      sortable: true,
      minWidth: '120px',
      wrap: true,
    },
    {
      name: 'Motivo',
      selector: row => row.motivo,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      minWidth: '150px',
      wrap: true,
    },
    {
      name: 'Atualizar dados bancários',
      selector: row => row.id,
      minWidth: '180px',
    },
  ];

export const PERGUNTAS_FREQUENTES = [
  {
    id: 111,
    ordem: 1,
    titulo: 'Qual o prazo para o pagamento após o reenvio dos dados bancários?',
    textos: [
      {
        id: 1110,
        ordem: 1,
        eLista: false,
        conteudos: [
          'O pagamento ocorrerá na conta do cliente em até 5 dias úteis após o reenvio do resgate.',
        ],
      },
    ],
  },
  {
    id: 222,
    ordem: 2,
    titulo: 'Cliente não pediu o resgate e por que ele ocorreu?',
    textos: [
      {
        id: 1111,
        ordem: 1,
        eLista: false,
        conteudos: [
          'O plano conjugado é composto pela cobertura de sobrevivência somado ao pecúlio. Quando o risco não é pago pelo cliente, a parcela fica inadimplente ocorrendo a comunicabilidade. Como funciona: o pecúlio é pago utilizando o saldo da cobertura de sobrevivência acumulada na reserva.',
        ],
      },
      {
        id: 2221,
        ordem: 1,
        eLista: false,
        conteudos: [
          'A regra do produto conjugado prevê também que somente poderão ser realizadas 12 comunicabilidades consecutivas. Se após 12 comunicabilidades não houver o pagamento do pecúlio, ocorrerá o resgate automático do plano.',
        ],
      },
      {
        id: 3331,
        ordem: 1,
        eLista: false,
        conteudos: [
          'Caso o saldo acumulado na reserva seja inferior ao valor da cobertura de pecúlio a ser paga através da comunicabilidade, o resgate também ocorrerá antes das 12 comunicabilidades consecutivas.',
        ],
      },
      {
        id: 4441,
        ordem: 1,
        eLista: false,
        conteudos: [
          'Vale ressaltar que, ocorrendo o resgate total do plano, a cobertura de pecúlio é cancelada.',
        ],
      },
    ],
  },
  {
    id: 333,
    ordem: 3,
    titulo: 'É possível realizar o pagamento em contas de terceiros?',
    textos: [
      {
        id: 1112,
        ordem: 1,
        eLista: false,
        conteudos: [
          'O resgate deve ser pago diretamente para os dados bancários do titular do plano. Havendo inconsistência bancária, o resgate poderá ser rejeitado mais de uma vez, até que seja informada uma conta válida para a conclusão do resgate.',
        ],
      },
      {
        id: 2222,
        ordem: 2,
        eLista: false,
        conteudos: [
          'Para os planos CRESCER, os dados bancários para reenvio do pagamento deverão ser de titularidade do cliente.',
        ],
      },
    ],
  },
  {
    id: 444,
    ordem: 4,
    titulo: 'Como evitar que o pagamento de resgate seja rejeitado?',
    textos: [
      {
        id: 1114,
        ordem: 1,
        eLista: false,
        conteudos: [
          'Alguns motivos que podem impedir que o pagamento de resgate aconteça:',
        ],
      },
      {
        id: 2224,
        ordem: 2,
        eLista: true,
        conteudos: [
          'Dados bancários de terceiros;',
          'Conta jurídica;',
          'Conta de investimentos;',
          'Alteração de agência;',
          'Conta inativa ou limitada para crédito.',
        ],
      },
      {
        id: 3334,
        ordem: 3,
        eLista: false,
        conteudos: [
          'Por isso é importante que o cliente mantenha seus dados cadastrais sempre atualizados para receber o seu resgate com tranquilidade.',
        ],
      },
    ],
  },
  {
    id: 555,
    ordem: 5,
    titulo: 'É possível estornar/cancelar o resgate e reativar o plano?',
    textos: [
      {
        id: 1115,
        ordem: 1,
        eLista: false,
        conteudos: [
          'Não é possível estornar um pedido de resgate. Uma vez solicitado a ação é irretratável.',
        ],
      },
    ],
  },
];

export const DADOS_OPERACAO = [
  { id: '1111', value: '001', text: '001 - Conta Corrente' },
  { id: '1112', value: '013', text: '013 - Conta Poupança' },
  { id: '1113', value: '023', text: '023 - Conta Caixa Fácil' },
  { id: '1114', value: '3701', text: '3701 - Conta Corrente' },
  { id: '1115', value: '1288', text: '1288 - Poupança Caixa Fácil' },
];
