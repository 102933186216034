import React from 'react';
import DataTable, { IDataTableProps } from 'react-data-table-component';
import * as S from './styles';

const Table = (props: IDataTableProps<any>) => {
  return (
    <S.ReactDataTable>
      <DataTable {...props} />
    </S.ReactDataTable>
  );
};

export default Table;
