import React from 'react';
import { Text, Divider } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ResultadoSolicitacaoTabelasProps } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';
import { COLUNAS_TABELA_COMPROVANTE_CONFIRMACAO_VALOR_CONTRIBUICAO } from 'previdencia/features/AlterarValorContribuicao/constants/constants';

const ResultadoSolicitacaoTabelas: React.FC<
  ResultadoSolicitacaoTabelasProps
> = ({ fundosAtualizados, fundosAntigos }) => {
  const tabelaFundosAntigos = () => {
    return fundosAntigos?.map(({ contribuicaoAtual, ...rest }) => ({
      valorContribuicao: tryGetMonetaryValueOrDefault(contribuicaoAtual),
      ...rest,
    }));
  };

  const tabelaFundosAtualizados = () => {
    return fundosAtualizados?.map(({ valor, ...rest }) => ({
      valorContribuicao: tryGetMonetaryValueOrDefault(valor),
      ...rest,
    }));
  };
  return (
    <>
      <Text variant="body01-sm" fontWeight="bold" marginBottom={5}>
        Como era:
      </Text>
      <Table
        data={tryGetValueOrDefault([tabelaFundosAntigos()], [])}
        columns={COLUNAS_TABELA_COMPROVANTE_CONFIRMACAO_VALOR_CONTRIBUICAO}
        noDataComponent="Não há dados para exibir."
        striped
        highlightOnHover
      />

      <Divider margin="30px 0 !important" />

      <Text variant="body01-sm" fontWeight="bold" marginBottom={5}>
        Como ficou:
      </Text>
      <Table
        data={tryGetValueOrDefault([tabelaFundosAtualizados()], [])}
        columns={COLUNAS_TABELA_COMPROVANTE_CONFIRMACAO_VALOR_CONTRIBUICAO}
        noDataComponent="Não há dados para exibir."
        striped
        highlightOnHover
      />
    </>
  );
};

export default ResultadoSolicitacaoTabelas;
