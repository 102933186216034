import { ColunasTabela } from 'painelAdministracao/types/colunasTabela';
import * as EDITAR_PROPS from 'painelAdministracao/types/IEditarFop';
import { IDataTableColumn } from 'react-data-table-component';

export const SUBTITULO_FOP =
  'Para atualizar, clique no FOP desejado, realize o upload do novo arquivo e atualize a versão.';

export const BOTOES = {
  EDITAR: 'Editar arquivo',
  ADICIONAR: 'Adicionar novo arquivo',
};

export const MODAL = {
  TITULO_EDITAR: 'Informe os dados para atualização: ',
  TITULO_NOVO: 'Informe os dados para cadastrar novo FOP: ',
  BOTAO_ARQUIVO: 'Selecione o arquivo',
  BOTAO_ATUALIZAR: 'Atualizar',
  BOTAO_CADASTRAR: 'Cadastrar',
  BOTAO_REMOVER: 'Remover FOP',
};

export const COLUNAS_TABELA: IDataTableColumn<ColunasTabela>[] = [
  {
    name: 'Simulador',
    selector: row => row.simulador,
  },
  {
    name: 'Previdência',
    selector: row => row.previdencia,
  },
  {
    name: 'Prestamista',
    selector: row => row.prestamista,
  },
  {
    name: 'Vida',
    selector: row => row.vida,
  },
];

export const INITIAL_FORMIK_STATE: EDITAR_PROPS.IFormikValuesEditarFop = {
  nome: '',
  codigo: '',
  versao: '',
  dataVersao: null,
  ativo: true,
  novoArquivo: {} as File,
  numeroProposta: '',
};

export const FORMIK_VALIDATION_MESSAGES = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
};

export const MENSAGEM_CONFIRMACAO_EXCLUSAO =
  "O FOP '{nomeFop}' será excluído. Confirma exclusão?";
