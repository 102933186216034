import React, { useContext, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  Disclaimer,
  Display,
  Skeleton,
  Text,
} from '@cvp/design-system/react';
import Icon from 'main/components/Icon/Icon';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { AppContext } from 'main/contexts/AppContext';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import {
  ApoliceDadosBancarios,
  CardResumoProduto,
  TabelaApoliceCarencias,
  TabelaApoliceCoberturas,
  TabelaApoliceSorteios,
} from 'seguros/components';
import { TEXTOS_DADOS_APOLICE_VIDA } from 'seguros/constants/ApoliceAssistencias';
import { listaCoberturasAssistenciasFactory } from 'seguros/factories/listaCoberturasAssistenciasFactory';
import {
  useApoliceDadosAvisosVida,
  useApoliceDadosCarenciaVida,
  useApoliceDadosCertificadoVida,
  useApoliceDadosCoberturaVida,
  useApoliceDadosSorteiosVida,
  useDadosSegurado,
  useTabelaApoliceAssistencias,
} from 'seguros/hooks';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import * as S from 'vida/features/DadosApolice/pages/styles';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import TabelasApoliceImpressao from 'seguros/components/TabelasApoliceImpressao/TabelasApoliceImpressao';
import { TipoSeguro } from 'seguros/types/DadosSegurado';

const DadosApoliceVida: React.FC = () => {
  const { clienteVida } = useContext(AppContext);
  const { goDadosSeguradoVida } = useVidaNavigation();
  const { dadosSegurado, loadingDadosSegurado } = useDadosSegurado();

  const { dados: dadosSorteios, loading: loadingSorteios } =
    useApoliceDadosSorteiosVida();
  const emailDefault = dadosSegurado?.entidade?.email;
  const { dados: dadosCobertura, loading: loadingCobertura } =
    useApoliceDadosCoberturaVida();

  const { dados: dadosCarencia, loading: loadingCarencia } =
    useApoliceDadosCarenciaVida();

  const { dados: avisos, loading: loadingAvisos } = useApoliceDadosAvisosVida();

  const { loading: loadingAssistencia, contratos } =
    useTabelaApoliceAssistencias();

  const { dados: dadosCertificadoVida, loading: loadingCertificado } =
    useApoliceDadosCertificadoVida();

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.DADOS_APOLICE_VIDA,
    parametrosEnvio: {
      numeroCertificadoBilhete: String(clienteVida.numCertificado),
      codigoProduto: clienteVida.codigoProduto,
    },
  };

  const exibirMensagem = checkIfAllItemsAreTrue([
    !dadosSorteios.length,
    !dadosCobertura.length,
    !dadosCarencia.length,
    !avisos.length,
    !contratos.length,
    !dadosCertificadoVida,
  ]);

  const loading = checkIfSomeItemsAreTrue([
    Boolean(loadingSorteios),
    Boolean(loadingCobertura),
    Boolean(loadingCarencia),
    Boolean(loadingAvisos),
    Boolean(loadingAssistencia),
    Boolean(loadingCertificado),
    Boolean(loadingDadosSegurado),
  ]);

  const primeiraConsultaIniciada = useRef<boolean>(false);
  useEffect(() => {
    if (loading) primeiraConsultaIniciada.current = true;
  }, [loading]);

  const listaCoberturasAssistencias = listaCoberturasAssistenciasFactory(
    clienteVida?.numCertificado,
    dadosCobertura,
    contratos,
  );

  return (
    <>
      <CardResumoProduto />

      <RenderConditional condition={primeiraConsultaIniciada.current}>
        <Display type="display-block">
          <Card>
            <Card.Content padding={[4, 4, 4]}>
              <RenderConditional condition={loading}>
                <Skeleton lines={3} />
              </RenderConditional>

              <RenderConditional condition={!loading}>
                <ApoliceDadosBancarios data={dadosCertificadoVida} />

                <TabelaApoliceCoberturas data={listaCoberturasAssistencias} />

                <TabelaApoliceCarencias data={dadosCarencia} />
                <TabelaApoliceSorteios data={dadosSorteios} />

                <TabelasApoliceImpressao
                  dadosCertificadoVida={dadosCertificadoVida}
                  dadosCobertura={dadosCobertura}
                  dadosCarencia={dadosCarencia}
                  dadosSorteios={dadosSorteios}
                />

                <S.ContainerImportantInfo className="hide-print">
                  <Text variant="body02-lg" margin>
                    {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.TITULO}
                  </Text>
                  <Text variant="body02-sm" margin>
                    {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.REEMBOLSO}
                  </Text>
                  <Text variant="body02-sm" margin>
                    {
                      TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE
                        .DETALHES_COBERTURAS
                    }
                  </Text>
                  <Text variant="body02-sm">
                    {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.IOF}
                  </Text>
                  <Text variant="caption-02">
                    {TEXTOS_DADOS_APOLICE_VIDA.OBS_IMPORTANTE.DETALHES_IOF}
                  </Text>
                </S.ContainerImportantInfo>

                <RenderConditional condition={exibirMensagem}>
                  <Display>
                    <S.DisclaimerSmall variant="error">
                      <Disclaimer.Content
                        text="Serviço não disponível para este certificado."
                        icon={<Icon name="information" />}
                      />
                    </S.DisclaimerSmall>
                  </Display>
                </RenderConditional>
              </RenderConditional>

              <Display className="hide-print">
                <Button variant="outlined" onClick={goDadosSeguradoVida}>
                  Voltar
                </Button>
                <EmailSenderModal
                  objetoEmail={objetoEmail}
                  defaultEmail={emailDefault}
                  seguro={TipoSeguro.VIDA}
                />
                <Button
                  data-testid="button-print"
                  variant="secondary"
                  onClick={() => window.print()}
                >
                  Imprimir
                </Button>
              </Display>
            </Card.Content>
          </Card>
        </Display>
      </RenderConditional>
    </>
  );
};

export default DadosApoliceVida;
