import styled from 'styled-components/macro';
import DataTable, {
  IDataTableColumn,
  IDataTableProps,
} from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { IRelatorioResgateResponse } from 'previdencia/features/Resgate/types/RelatorioResgateResponse';

export const Linked = styled(Link)(
  ({ theme: { breakpoint, color, font } }) => ({
    '&&': {
      cursor: 'pointer',
      color: color.brandPrimary.dark,
      fontSize: font.size.md,
      [breakpoint.lg()]: {
        maxWidth: '150px',
      },
    },
  }),
);

export const LinkedTable = styled(Link)(({ theme: { color, font } }) => ({
  '&&': {
    cursor: 'pointer',
    color: color.brandPrimary.dark,
    fontSize: font.size.md,
  },
}));

export const Table = styled(DataTable<IDataTableProps>)<any>`
  .rdt_TableCol,
  .rdt_TableCell {
    font-size: ${({ theme }) => theme.font.size.sm};
    font-family: ${({ theme }) => theme.font.family.base};
  }
  .rdt_TableCol {
    font-weight: ${({ theme }) => theme.font.weight.lg};
    text-align: center;
    &:hover {
      color: ${({ theme }) => theme.color.brandPrimary.light};
    }
  }
`;

export const TableListaResgates = styled(DataTable<IRelatorioResgateResponse>)`
  .rdt_TableCol,
  .rdt_TableCell {
    font-size: ${({ theme }) => theme.font.size.sm};
    font-family: ${({ theme }) => theme.font.family.base};
  }
  .rdt_TableCol {
    font-weight: ${({ theme }) => theme.font.weight.lg};
    text-align: center;
    &:hover {
      color: ${({ theme }) => theme.color.brandPrimary.light};
    }
  }
`;
