import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import * as S from 'main/components/Table/customStyles';
import { IDataTableColumn } from 'react-data-table-component';
import { IDetalhesSeguroCanceladoDataColumn } from '../types/IResponseDetalhesSeguroCancelado';
import { IResponseListaSegurosCancelados } from '../types/IResponseListaSegurosCancelados';
import ListColumnActionReversao from './ListColumnActionReversao';
import { Text } from './styles';

export const gerarColunasReversao = (
  callToAction: (proposta: IResponseListaSegurosCancelados) => void,
): IDataTableColumn<IDetalhesSeguroCanceladoDataColumn>[] => [
  {
    name: 'Proposta',
    selector: row => row.numeroProposta,
    center: true,
    width: '180px',
    cell: proposta => (
      <S.Linked
        title="Veja aqui os detalhes da proposta"
        to={{
          pathname: `/prestamista/cancelamentos/${proposta.numContrato}/detalhes`,
          state: {
            proposta,
          },
        }}
      >
        {proposta.numeroPropostaEstipulante}
      </S.Linked>
    ),
  },
  {
    name: 'Nome',
    sortable: true,
    selector: row => row.nomeSegurado,
    center: true,
    width: '250px',
    cell: ({ nomeSegurado }) => (
      <Text title={nomeSegurado}>{nomeSegurado}</Text>
    ),
  },
  {
    name: 'CPF/CNPJ',
    center: true,
    selector: row => row.cpfCnpj,
    cell: ({ cpfCnpj }) => <span>{maskCpfCnpj(cpfCnpj)}</span>,
  },
  {
    name: 'Valor do Prêmio',
    selector: row => row.valorPremio,
    sortable: true,
    center: true,
  },
  {
    name: 'Agência',
    selector: row => row.codigoAgenciaVenda,
    center: true,
    width: '70px',
  },
  {
    name: 'Data limite para retenção',
    selector: row => row.dtaFimReversao,
    sortable: true,
    center: true,
  },
  {
    name: 'Reter prestamista',
    selector: row => row.numeroProposta,
    sortable: false,
    center: true,
    cell: cell => {
      const proposta = cell as unknown as IResponseListaSegurosCancelados;
      return (
        <ListColumnActionReversao
          dados={proposta}
          callToAction={() => callToAction(proposta)}
        />
      );
    },
  },
];
