export const obterEndpointContratosPrestamista = (): string =>
  '/PortalEconomiario/PECO_RecuperarContratosPorSegmento';

export const obterEndpointEnviarEmailPrestamista = (): string =>
  '/PortalEconomiario/PECO_EnviarEmailPrestamista';

export const PECOS_PRESTAMISTA = {
  ListarDadosCadastroSeguroPrestamista:
    'PECO_ListarDadosCadastroSeguroPrestamista',
  AtualizarDadosCadastroSeguroPrestamista:
    'PECO_AtualizarDadosCadastroSeguroPrestamista',
  BuscarEnderecoCep: 'PECO_BuscarEnderecoCep',
  DetalhesSeguroPrestamita: 'PECO_DetalhesSeguroPrestamita',
  GerarCertificadoSeguroPrestamista: 'PECO_GerarCertificadoSeguroPrestamista',
  DetalhesSeguroPrestamista: 'PECO_DetalhesSeguroPrestamista',
  ObterAssistenciasCoberturasPorContratoPrestamista:
    'PECO_ObterAssistenciasCoberturasPorContratoPrestamista',
  ObterBeneficiarioPorContratoPrestamista:
    'PECO_ObterBeneficiarioPorContratoPrestamista',
};
