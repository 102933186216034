import { TooltipModalWrapper } from 'main/components/Wrappers/TooltipModalWrapper';
import { TEXTO_TOOLTIP_RESERVA } from 'previdencia/constants/texts';
import {
  MultifundoPj,
  MultifundoPjData,
} from 'previdencia/types/MultifundosPJ';
import { CellTypeTabelaFundos } from 'previdencia/types/Tabelas';
import { IDataTableColumn } from 'react-data-table-component';
import { TLinhaExpansivelColumn } from '../types/TabelaMultifundos.types';

export const COLUNAS_TABELA_FUNDOS_PJ: IDataTableColumn<MultifundoPjData>[] = [
  {
    selector: row => row.check,
    wrap: true,
    width: '50px',
    name: '',
  },
  {
    name: 'Fundo',
    selector: row => row.descricaoFundo,
    minWidth: '380px',
    cell: ({ descricaoFundo }: CellTypeTabelaFundos) => (
      <p title={descricaoFundo}>{descricaoFundo}</p>
    ),
  },
  {
    name: 'Perfil do Risco',
    selector: row => row.perfil,
    minWidth: '180px',
    maxWidth: '200px',
  },
  {
    name: 'Rentabilidade (últimos 12 meses)',
    selector: row => row.rentabilidade,
    minWidth: '100px',
    maxWidth: '170px',
  },
  {
    name: 'Saldo',
    minWidth: '150px',
    selector: row => row.valorSaldo,
  },
];

export const COLUNAS_TABELA_REDISTRIBUIR_FUNDOS_PJ: IDataTableColumn<MultifundoPjData>[] =
  [
    {
      selector: row => row.check,
      width: '50px',
      wrap: true,
      name: '',
    },
    {
      name: 'Fundo',
      selector: row => row.descricaoFundo,
      cell: ({ descricaoFundo }: CellTypeTabelaFundos) => (
        <p title={descricaoFundo}>{descricaoFundo}</p>
      ),
      minWidth: '35vw',
    },
    {
      selector: row => row.valorContribuicao,
      minWidth: '180px',
      name: 'Valor Contribuição',
    },
  ];

export const COLUNAS_TABELA_RESUMO_FUNDOS_PJ: IDataTableColumn<MultifundoPj>[] =
  [
    {
      selector: row => row.descricaoFundo,
      name: 'Fundo',
      minWidth: '550px',
      cell: ({ descricaoFundo }: CellTypeTabelaFundos) => (
        <p title={descricaoFundo}>{descricaoFundo}</p>
      ),
    },
    {
      selector: row => row.codigoPerfilFundo,
      name: 'Perfil do Risco',
      minWidth: '180px',
    },
    {
      selector: (row: MultifundoPjData) => row.valorRetirada,
      name: 'Valor da Retirada',
      minWidth: '150px',
    },
  ];

export const COLUNAS_TABELA_EXPANSIVEL_MULTIFUNDOS_PJ: IDataTableColumn<TLinhaExpansivelColumn>[] =
  [
    {
      name: (
        <TooltipModalWrapper left={100} top={0} title="Reserva">
          {TEXTO_TOOLTIP_RESERVA}
        </TooltipModalWrapper>
      ),
      width: '120px',
      selector: row => row.descricaoReserva,
    },
    {
      name: 'Saldo',
      width: '150px',
      selector: row => row.saldo,
    },
    {
      selector: row => row.tipoTransferencia,
      name: 'Tipo Transferência',
      maxWidth: '250px',
    },
    {
      name: 'Valor R$',
      selector: row => row.valorInput,
      maxWidth: '250px',
    },
  ];

export const COLUNAS_DESTINO_EXPANSIVEL_MULTIFUNDOS_PJ: IDataTableColumn<TLinhaExpansivelColumn>[] =
  [
    {
      name: (
        <TooltipModalWrapper left={100} top={0} title="Reserva">
          {TEXTO_TOOLTIP_RESERVA}
        </TooltipModalWrapper>
      ),
      width: '120px',
      selector: row => row.descricaoReserva,
    },
    {
      name: 'Saldo',
      width: '150px',
      selector: row => row.saldo,
    },
    {
      name: 'Valor a transferir R$',
      maxWidth: '250px',
      selector: row => row.valorInput,
    },
  ];
