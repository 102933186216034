import { formatarData } from 'main/utils';
import { IDataTableColumn } from 'react-data-table-component';
import { IDadosSinistroResponse } from 'seguros/types/ConsultaSinistroResponse';

export const COLUNAS_TABELA_CONSULTA_SINISTRO: IDataTableColumn<IDadosSinistroResponse>[] =
  [
    {
      name: 'Contrato',
      selector: row => row.numeroContrato,
      minWidth: '100px',
    },
    {
      name: 'Cobertura',
      selector: row => row.coberturas,
      minWidth: '280px',
      wrap: true,
    },
    {
      name: 'Causa',
      selector: row => row.causas,
      minWidth: '280px',
      wrap: true,
    },
    {
      name: 'Data do Sinistro',
      selector: row => row.dataHoraSinistro,
      cell: ({ dataHoraSinistro }) => formatarData(dataHoraSinistro),
      minWidth: '100px',
    },
    {
      name: 'Data da Atualização',
      selector: row => row.dataHoraAtualizacao,
      cell: ({ dataHoraAtualizacao }) => formatarData(dataHoraAtualizacao),
      minWidth: '100px',
    },
  ];

export const TEXTOS_CONSULTA_SINISTRO = {
  TITULO: 'Consulta de Sinistro',
  TABELA_SEM_DADOS:
    'Não identificamos solicitação de abertura de sinistro neste certificado. Favor, verificar junto ao cliente.',
  OBSERVACAO: `A Caixa Seguros tem um prazo de 30 dias para a conclusão do processo
de análise de documentação complementar ou para pedidos de
reanálise.`,
};
