import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { IDataTableColumn } from 'react-data-table-component';
import { ITituloCapitalizacao } from 'seguros/types/IResponseCertificadosResumo';

export const COLUNAS_TABELA_SORTEIOS: IDataTableColumn<ITituloCapitalizacao>[] =
  [
    {
      name: 'Nº de Sorteio',
      selector: (row: ITituloCapitalizacao) => row.numeroSorteio,
      cell: (row: ITituloCapitalizacao) => row.numeroSorteio,
    },
    {
      name: 'Nº do Título',
      selector: row => row.numeroTitulo,
    },
    {
      name: 'Com seu número de sorteio, você concorre a',
      selector: (row: ITituloCapitalizacao) => row.valorTitulo,
      cell: ({ valorTitulo }: ITituloCapitalizacao) =>
        tryGetMonetaryValueOrDefault(valorTitulo),
      maxWidth: '200px',
      center: true,
    },
  ];

export const SEM_DADOS = 'Não há dados de sorteio a serem exibidos.';

export const DESC_SORTEIO = 'NÚMERO DE SORTEIO';
