import { IDataTableColumn } from 'react-data-table-component';
import {
  formatarCpfCnpj,
  formatarParticipacao,
} from '../factories/dadosBeneficiriosFactory';
import { IDadosBeneficiarios } from '../types/IBeneficiarios';

export const COLUNA_NOME = 'Nome';
export const COLUNA_CPF = 'CPF';
export const COLUNA_GRAU_PARENTESCO = 'Grau Parentesco';
export const COLUNA_PARTICIPACAO = 'Participação';

export const SEM_DADOS_BENEFICIARIOS =
  'Não há dados de beneficiário a serem exibidos.';

export const COLUNAS_TABELA_BENEFICIARIOS: IDataTableColumn<IDadosBeneficiarios>[] =
  [
    {
      name: COLUNA_NOME,
      selector: (row: IDadosBeneficiarios) => row.nomeRazSocial,
    },
    {
      name: COLUNA_CPF,
      cell: (row: IDadosBeneficiarios) => formatarCpfCnpj(row.numeroCPFCPNJ),
      selector: row => row.numeroCPFCPNJ,
    },
    {
      name: COLUNA_GRAU_PARENTESCO,
      selector: (row: IDadosBeneficiarios) => row.grauParentesco,
    },
    {
      selector: row => row.pctPartBeneficiario,
      name: COLUNA_PARTICIPACAO,
      cell: (row: IDadosBeneficiarios) =>
        formatarParticipacao(row.pctPartBeneficiario),
    },
  ];
