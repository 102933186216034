import React, { useMemo } from 'react';
import { formatarDataHora } from 'main/utils';
import {
  DetalhesPortabilidade,
  getDefaultInstance,
} from '../../types/DetalhesPortabilidade';
import { IDataTableColumn } from 'react-data-table-component';
import { HistoricoSituacao } from '../../types/HistoricoSituacao';
import { TableExtendita } from 'portabilidade/components/Table';

type HistoricoSituacaoPortabilidadeProps = {
  dadosPortabilidade?: DetalhesPortabilidade;
};

const HistoricoSituacaoPortabilidade: React.FunctionComponent<
  HistoricoSituacaoPortabilidadeProps
> = ({ dadosPortabilidade }) => {
  const colunas = useMemo(
    (): IDataTableColumn<HistoricoSituacao>[] => [
      {
        name: 'Situação',
        selector: row => row.situacao,
        sortable: false,
      },
      {
        name: 'Data e hora do registro',
        selector: row => row.dataHorarioRegistro,
        cell: ({ dataHorarioRegistro }: HistoricoSituacao) =>
          formatarDataHora(dataHorarioRegistro),
        sortable: true,
      },
    ],
    [],
  );
  return (
    <TableExtendita
      columns={colunas}
      noHeader
      title="Histórico da Situação"
      data={dadosPortabilidade?.historicoSituacaoRetencao ?? []}
      noDataComponent="Não há dados para exibir."
    />
  );
};

HistoricoSituacaoPortabilidade.defaultProps = {
  dadosPortabilidade: getDefaultInstance(),
};

export default HistoricoSituacaoPortabilidade;
