import { Card } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableExtendita, TableSkeleton } from 'portabilidade/components/Table';
import {
  Linked,
  StatusRetencao,
} from 'portabilidade/features/retencao/components';
import { COLUNAS_TABELA_RETENCAO } from 'portabilidade/features/retencao/constants';
import { useListaPortabilidade } from 'portabilidade/features/retencao/hooks/usePortabilidades';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationStatePortabilidade } from 'portabilidade/features/retencao/types/Portabilidade';
import { IDataTableColumn } from 'react-data-table-component';
import { ResumoPortabilidade } from 'portabilidade/types';

const ListagemPortabilidades: React.FunctionComponent = () => {
  const location = useLocation<LocationStatePortabilidade>();
  const unidadeEntradaPainel = tryGetValueOrDefault(
    [location.state?.unidadeTable],
    '',
  );

  const { response, loading } = useListaPortabilidade(unidadeEntradaPainel);

  const colunas = useMemo(
    (): IDataTableColumn<ResumoPortabilidade>[] => [
      {
        name: 'Nº Portabilidade',
        selector: row => row.numeroPortabilidade,
        cell: ({ numeroPortabilidade, id }: ResumoPortabilidade) => (
          <Linked to={`/portabilidades/${id}`}>{numeroPortabilidade}</Linked>
        ),
      },
      ...COLUNAS_TABELA_RETENCAO,
      {
        name: 'Status',
        sortable: true,
        selector: row => row.codigoSituacaoRetencao,
        cell: ({
          descricaoSituacaoRetencao,
          codigoSituacaoRetencao,
        }: ResumoPortabilidade) => (
          <StatusRetencao esperandoAcaoRetencao={codigoSituacaoRetencao === 1}>
            {descricaoSituacaoRetencao}
          </StatusRetencao>
        ),
      },
    ],
    [],
  );

  return (
    <Card style={{ minHeight: 'auto' }}>
      <Card.Content>
        {loading ? (
          <TableSkeleton colunas={colunas} />
        ) : (
          <TableExtendita
            noHeader
            responsive
            data={response?.portabilidades ?? []}
            columns={colunas}
            noDataComponent="Não há dados para exibir."
            pagination
            paginationPerPage={10}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
          />
        )}
      </Card.Content>
    </Card>
  );
};

export default ListagemPortabilidades;
