import { FormikProps } from 'formik';
import { ResponseObterDadosCertificadoPrestamista } from 'prestamista/types/ICertifucatiPrestamista';
import * as RESPONSE_TYPES from 'prestamista/features/dadosSegurado/types/DadosSeguradoResponse';

export interface IDadosSeguradoContextData {
  dadosSegurado?: RESPONSE_TYPES.IListarDadosCadastroSeguroPrestamistaResponse;
  loadingDadosSegurado?: boolean;
  dadosCertificadoPrestamista?: ResponseObterDadosCertificadoPrestamista;
}

export interface IFormikValues {
  cep: string;
  endereco: string;
  numeroEndereco: string;
  complementoEndereco: string;
  bairro: string;
  cidade: string;
  uf: string;
  ddd1: string;
  telefone1: string;
  ddd2: string;
  telefone2: string;
  email: string;
  nomeSocial: string;
  utilizarNomeSocial: boolean;
  nome: string;
}

export type MsgInputFormikError = {
  [key: string]: string;
};

export interface IFormAtualizacaoDadosSeguradoProps {
  formik: FormikProps<IFormikValues>;
  handleCepChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum StatusCertificado {
  ATIVO = 'A',
}
