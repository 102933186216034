import React, { useEffect, useState } from 'react';
import { Card } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import SkeletonLoading from 'main/components/SkeletonLoading';
import * as ConditionalUtils from 'main/utils/conditional';
import { useAuth } from 'main/features/Auth/hooks';
import RenderConditional from 'main/components/RenderConditional';
import {
  IDadosBasePessoa,
  ISubscricaoPessoaFisica,
  ISubscricaoSocio,
} from 'contratosPrestamista/types';
import { usePecoFluxoPJ } from 'contratosPrestamista/features/dpseletronica/hooks/usePecoFluxoPJ';
import { obterObjetoSubscricaoPessoaFisica } from 'contratosPrestamista/features/dpseletronica/factories';
import {
  PARAMETROS_DPS,
  MENSAGEM_TABELA_DADOS,
} from 'main/constants/parametrosDps';
import ModalGerenciarSocios from '../../../components/ModalGerenciarSocios';
import {
  calcularValorAcumulo,
  calcularValorCapitalSeguradoIndividual,
  converterValorContrato,
} from 'main/utils/dpsUtils';
import { ITabelaSociosProps } from 'contratosPrestamista/types/ITabelaSociosProps';
import { IStatusPropostaDpsAgrupado } from 'contratosPrestamista/features/dpsEletronicaPostecipado/types/IResponseListarStatusPropostaDps';
import {
  DPS_CANCELADA,
  NAO_NECESSITA_DPS,
  NECESSITA_DPS,
  STATUS_NAO_EXIBIR_ACAO_DPS,
  TIPO_PESSOA_DPS,
} from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/MapeamentoStatus';
import { usePecoFluxoPF } from 'contratosPrestamista/features/dpseletronica/hooks/usePecoFluxoPF';
import masks from 'main/utils/masks';
import { INITIAL_STATE_MODAL_RETORNO_ACAO_DPS } from 'main/constants/dps';
import { usePecoReenviarLinkDps } from 'contratosPrestamista/features/dpsEletronicaPostecipado/hooks/usePecoReenviarLinkDps';
import { usePecoInserirAtualizarPropostaDps } from 'contratosPrestamista/hooks/usePecoAtualizarStatusPropostaDps';
import { toastError } from 'main/hooks/useToast';
import { usePecoSolicitarDps } from 'contratosPrestamista/hooks/usePecoSolicitarDps';
import { construirPayloadSolicitarDps } from 'contratosPrestamista/factories/construirObjetoSolicitarDps';
import { completarDigitosCpf } from 'main/utils/cpf_cnpj';
import { useVerificarNecessidadeDps } from 'contratosPrestamista/hooks/useVerificarNecessidadeDps';
import { IDadosBasicoProposta } from 'contratosPrestamista/types/IDadosBasicoProposta';
import ModalRetornoAcaoDps from 'main/components/ModalRetornoAcaoDps/ModalRetornoAcaoDps';
import { ColunasTabelaSocios } from '../constants/ColunasTabelaSocio';
import { useAdicionarSocio } from '../../../hooks/useAdicionarSocio';
import {
  obterRetornoAdicionarSocio,
  validarDataVigenteDps,
} from '../utils/helpers';
import { InitialStateModalRetornoAcaoDps } from '../../../../main/components/ModalRetornoAcaoDps/types/InitialStateModalRetornoAcaoDps';
import { formatarDataAdicionandoSeparador } from 'main/utils';
import {
  TipoPessoaEnum,
  TipoPortalEconomiario,
} from '../constants/EnumTipoPessoa';

const TabelaSocios: React.FC<ITabelaSociosProps> = ({
  capitalSegurado,
  dataInicioVigencia,
  numeroProposta,
  socios,
  dadosProposta,
  children,
}) => {
  const [modalRetornoAcaoDps, setModalRetornoAcaoDps] =
    useState<InitialStateModalRetornoAcaoDps>(
      INITIAL_STATE_MODAL_RETORNO_ACAO_DPS,
    );

  const { user } = useAuth();
  const [loadingMapearDadosSocio, setLoadingMapearDadosSocio] = useState(false);
  const [openModalReenviarLink, setOpenModalReenviarLink] =
    useState<boolean>(false);
  const [openModalSolicitarDps, setOpenModalSolicitarDps] =
    useState<boolean>(false);
  const [openModalValidarSocio, setOpenModalValidarSocio] =
    useState<boolean>(false);
  const [listaSocios, setListaSocios] = useState<IStatusPropostaDpsAgrupado[]>(
    [] as IStatusPropostaDpsAgrupado[],
  );
  const [openModalAdicionarSocio, setModalAdicionarSocios] =
    useState<boolean>(false);

  const [validarSocio, setValidarSocio] = useState<ISubscricaoPessoaFisica>(
    {} as ISubscricaoPessoaFisica,
  );
  const servicePJ = usePecoFluxoPJ();
  const servicePF = usePecoFluxoPF();
  const serviceReenviarLinkDps = usePecoReenviarLinkDps();
  const serviceAtualizarDps = usePecoInserirAtualizarPropostaDps();
  const serviceSolicitarDps = usePecoSolicitarDps();
  const serviceVerificarNecessidadeDps = useVerificarNecessidadeDps();
  const serviceAdicionarSocio = useAdicionarSocio();

  const obterPayloadVerificarNecessiadeDps = (
    novoItem: IStatusPropostaDpsAgrupado,
    valorAcumulo: number,
  ): IDadosBasicoProposta => ({
    cpf: novoItem.numCpfSocio?.toString(),
    numeroProposta: dadosProposta.nossoNumero?.toString(),
    valorAcumulo,
    capitalSegurado,
    cnpj: dadosProposta.numCpfCnpj.toString(),
    seqProposta: dadosProposta.seqProposta,
  });

  const obterValorImportanciaSegurada = async (cpf: string) => {
    const result = await servicePJ.fetchDataImportanciaSegurada({
      cpfCnpjCliente: cpf,
    });

    return converterValorContrato(result?.entidade?.valorTotalContratos);
  };

  const ratearValorImportanciaSegurada = (
    sociosToMap: IStatusPropostaDpsAgrupado[],
  ): IStatusPropostaDpsAgrupado[] => {
    return sociosToMap.map(item => {
      const qtdeSocios = ConditionalUtils.tryGetValueOrDefault(
        [sociosToMap],
        [],
      ).length;

      return {
        ...item,
        capitalSegurado: calcularValorCapitalSeguradoIndividual(
          capitalSegurado,
          qtdeSocios,
        ),
      };
    });
  };
  const obterStatusDpsSampleMed = async (
    cpf: string | number,
  ): Promise<boolean> => {
    const statusDpsSM = await serviceReenviarLinkDps.fetchConsultarStatusDps({
      numeroProposta,
      cpf,
    });

    return !!statusDpsSM?.entidade?.statusAtualFornecedor;
  };

  const mapearDadosSocios = async (
    sociosToMap: IStatusPropostaDpsAgrupado[],
  ) => {
    setLoadingMapearDadosSocio(true);
    const subscricaoSocios: IStatusPropostaDpsAgrupado[] = await Promise.all(
      ConditionalUtils.tryGetValueOrDefault(
        [sociosToMap],
        [] as IStatusPropostaDpsAgrupado[],
      ).map(async item => {
        const novoItem = item;
        const valorContrato = await obterValorImportanciaSegurada(
          novoItem.numCpfSocio?.toString(),
        );
        const qtdeSocios = ConditionalUtils.tryGetValueOrDefault(
          [sociosToMap],
          [],
        ).length;

        const capitalSeguradoIndividual =
          calcularValorCapitalSeguradoIndividual(capitalSegurado, qtdeSocios);

        const valorAcumulo = calcularValorAcumulo(
          valorContrato,
          capitalSeguradoIndividual,
        );

        const naoExibirAcao = STATUS_NAO_EXIBIR_ACAO_DPS.includes(
          item.statusDps,
        );

        const isDataVigente = validarDataVigenteDps(
          ConditionalUtils.tryGetValueOrDefault([dataInicioVigencia], ''),
        );

        if ([NECESSITA_DPS].includes(item.statusDps)) {
          const statusSampleMed = await obterStatusDpsSampleMed(
            novoItem.numCpfSocioFormatado,
          );

          novoItem.reenviarLinkDps = ConditionalUtils.checkIfAllItemsAreTrue([
            !naoExibirAcao,
            isDataVigente,
            statusSampleMed,
          ]);

          novoItem.solicitarDps = ConditionalUtils.checkIfAllItemsAreTrue([
            !naoExibirAcao,
            isDataVigente,
            !statusSampleMed,
          ]);
        }

        if (!novoItem.dpsVerificada) {
          const payloadDadosBasicoProposta = obterPayloadVerificarNecessiadeDps(
            novoItem,
            valorAcumulo,
          );
          const dadosDps =
            await serviceVerificarNecessidadeDps.verificarExigenciaDps(
              payloadDadosBasicoProposta,
            );
          novoItem.necessitaDps = dadosDps.necessitaDps;
          novoItem.dpsVerificada = true;
          novoItem.statusDps = ConditionalUtils.getTernaryResult<string>(
            dadosDps.necessitaDps,
            NECESSITA_DPS,
            NAO_NECESSITA_DPS,
          );

          if (novoItem.novoSocio) {
            await serviceAdicionarSocio.incluirSocioSmart(
              payloadDadosBasicoProposta,
              dadosDps.necessitaDps,
            );
          }
        }

        return {
          ...novoItem,
          numCpfSocio: completarDigitosCpf(novoItem.numCpfSocio?.toString()),
          valorAcumulo,
          capitalSegurado: capitalSeguradoIndividual,
          telefone: masks.numberOnly.mask(`${item.ddd}${item.telefone}`),
        };
      }),
    );

    setListaSocios(subscricaoSocios);
    setLoadingMapearDadosSocio(false);
  };

  const obterDadosSubscricao = async (cpf: string) => {
    setValidarSocio({} as ISubscricaoPessoaFisica);
    const result = await servicePF.fetchDataConsultarSubscricao({
      cpfCnpj: cpf,
      idProduto: PARAMETROS_DPS.ID_PRODUTO,
    });
    setValidarSocio({
      ...obterObjetoSubscricaoPessoaFisica(result),
      cpf,
    });
  };

  const handleModalValidarSocio = () => {
    setOpenModalValidarSocio(!openModalValidarSocio);
  };

  const handleSubmitReenviarLink = async (socio: unknown) => {
    const dadosSocioForm = socio as IStatusPropostaDpsAgrupado;
    const retornoServiceReenviarLinkDps =
      await serviceReenviarLinkDps.fetchDataReenviarLink({
        cpf: dadosSocioForm.cpf,
        numeroProposta,
        email: dadosSocioForm.email,
        sexo: dadosSocioForm.sexo,
        telefone: dadosSocioForm.telefone,
        dataNascimento: formatarDataAdicionandoSeparador(
          dadosSocioForm.dataNascimento,
        ),
      });
    setOpenModalReenviarLink(!openModalReenviarLink);

    setModalRetornoAcaoDps(prev => ({
      ...prev,
      isOpen: !prev.isOpen,
      message: ConditionalUtils.tryGetValueOrDefault(
        [retornoServiceReenviarLinkDps?.mensagens?.[0]?.descricao],
        '',
      ),
    }));
  };

  const handleSubmitSolicitarDps = async (socio: unknown) => {
    const dadosSocioForm = socio as IDadosBasePessoa;

    const retornoServiceSolicitarDps =
      await serviceSolicitarDps.fecthSolicitarDps(
        construirPayloadSolicitarDps(
          {
            ...dadosSocioForm,
            numeroProposta,
            codigoUsuario: user.agenciaVinculada,
            valorAcumulo: ConditionalUtils.tryGetValueOrDefault(
              [capitalSegurado],
              0,
            ),
          },
          ConditionalUtils.getTernaryResult(
            dadosProposta.tipoPessoa === TIPO_PESSOA_DPS.JURIDICA,
            'PJ',
            'PF',
          ),
        ),
      );

    setOpenModalSolicitarDps(!openModalSolicitarDps);

    setModalRetornoAcaoDps(prev => ({
      ...prev,
      isOpen: !prev.isOpen,
      message: ConditionalUtils.tryGetValueOrDefault(
        [retornoServiceSolicitarDps?.mensagens?.[0]?.descricao],
        '',
      ),
    }));
  };
  const handleAdicionarSocio = async (socio: unknown) => {
    const socioCast = socio as IStatusPropostaDpsAgrupado;
    const socioItem = listaSocios.find(x => x.numCpfSocio === socioCast.cpf);
    if (socioItem) {
      toastError('O sócio informado já encontra-se cadastrado!');
      return;
    }
    const objetoNovoSocio = obterRetornoAdicionarSocio(socio, listaSocios);

    await mapearDadosSocios(ratearValorImportanciaSegurada(objetoNovoSocio));

    setModalAdicionarSocios(!openModalAdicionarSocio);
  };
  const handleExcluirSocio = async (cpf: string, closeModal: () => void) => {
    await serviceAtualizarDps.fetchAtualizarPropostaDps({
      cpfCnpj: dadosProposta.numCpfCnpj.toString(),
      CpfCnpjSocio: cpf,
      numeroContrato: Number(numeroProposta),
      statusDps: DPS_CANCELADA,
      portalEconomiario: ConditionalUtils.getTernaryResult(
        dadosProposta.tipoPessoa === TipoPessoaEnum.juridica,
        TipoPortalEconomiario.juridica,
        TipoPortalEconomiario.fisica,
      ),
      email: dadosProposta.email,
      dataNascimento: dadosProposta.dataNascimento,
    });
    closeModal();
    const sociosUpdated = listaSocios
      .filter(x => x.numCpfSocio !== cpf.toString())
      .map(item => ({ ...item, dpsVerificada: false }));
    await mapearDadosSocios(ratearValorImportanciaSegurada(sociosUpdated));
    setValidarSocio({} as ISubscricaoPessoaFisica);
  };

  const handleOpenModalReenviarLink = async (numCpfSocio: string | number) => {
    const cpfSocio = masks.numberOnly.mask(numCpfSocio?.toString());
    await obterDadosSubscricao(
      ConditionalUtils.tryGetValueOrDefault([cpfSocio], ''),
    );
    setValidarSocio(prevState => ({
      ...prevState,
      cpf: cpfSocio,
    }));
    setOpenModalReenviarLink(!openModalReenviarLink);
  };

  const handleOpenModalSolicitarDps = async (numCpfSocio: string | number) => {
    const cpfSocio = masks.numberOnly.mask(numCpfSocio?.toString());
    setValidarSocio(prevState => ({
      ...prevState,
      cpf: cpfSocio,
    }));
    await obterDadosSubscricao(
      ConditionalUtils.tryGetValueOrDefault([cpfSocio], ''),
    );
    setOpenModalSolicitarDps(!openModalSolicitarDps);
  };

  const handleSubmitRetornoAcaoDps = (): void => {
    setModalRetornoAcaoDps(INITIAL_STATE_MODAL_RETORNO_ACAO_DPS);
    mapearDadosSocios(socios);
  };

  useEffect(() => {
    if (
      ConditionalUtils.checkIfAllItemsAreTrue([
        !!capitalSegurado,
        socios?.length > 0,
      ])
    )
      mapearDadosSocios(socios);
  }, [socios, capitalSegurado]);

  if (
    ConditionalUtils.checkIfSomeItemsAreTrue([
      serviceVerificarNecessidadeDps.loadingVerificarNecessidadeDps,
      servicePJ.loadingConsultarSubscricao,
      servicePF.loadingConsultarSubscricao,
      servicePJ.loadingObterIs,
      loadingMapearDadosSocio,
      serviceAdicionarSocio.loadingIncluirSocioSmart,
      serviceAtualizarDps.loadingAtualizarPropostaDps,
    ])
  )
    return <SkeletonLoading />;
  return (
    <Card>
      <Card.Content>
        <RenderConditional
          condition={ConditionalUtils.checkIfSomeItemsAreTrue([
            dadosProposta?.socios?.length > 0,
            listaSocios?.length > 0,
          ])}
        >
          <Table
            highlightOnHover
            striped
            responsive
            className="disableReactDataTableOverflow"
            columns={ColunasTabelaSocios(
              handleOpenModalReenviarLink,
              handleOpenModalSolicitarDps,
            )}
            data={listaSocios}
            pagination
            paginationPerPage={10}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
            noDataComponent={MENSAGEM_TABELA_DADOS}
          />
        </RenderConditional>

        <br />
        <br />

        {children}

        <ModalGerenciarSocios
          dadosPessoa={validarSocio}
          loading={servicePF.loadingConsultarSubscricao}
          openModal={openModalValidarSocio}
          handleExcluirSocio={cpf =>
            handleExcluirSocio(cpf, () =>
              setOpenModalValidarSocio(!openModalValidarSocio),
            )
          }
          handleSubmitForm={handleModalValidarSocio}
          handleClose={() => setOpenModalValidarSocio(!openModalValidarSocio)}
          tipo="VALIDAR"
          disableDelete
        />

        <ModalGerenciarSocios
          dadosPessoa={{} as ISubscricaoSocio}
          handleClose={() => setModalAdicionarSocios(!openModalAdicionarSocio)}
          handleSubmitForm={handleAdicionarSocio}
          loading={ConditionalUtils.checkIfSomeItemsAreTrue([
            servicePJ.loadingConsultarSubscricao,
            serviceVerificarNecessidadeDps.loadingVerificarNecessidadeDps,
          ])}
          openModal={openModalAdicionarSocio}
          tipo="ADICIONAR"
          disableDelete
        />

        <ModalGerenciarSocios
          dadosPessoa={validarSocio}
          handleClose={() => setOpenModalReenviarLink(!openModalReenviarLink)}
          handleExcluirSocio={cpf =>
            handleExcluirSocio(cpf, () =>
              setOpenModalReenviarLink(!openModalReenviarLink),
            )
          }
          handleSubmitForm={handleSubmitReenviarLink}
          loading={ConditionalUtils.checkIfSomeItemsAreTrue([
            servicePJ.loadingConsultarSubscricao,
            serviceReenviarLinkDps.loadingReenviarLink,
          ])}
          openModal={openModalReenviarLink}
          tipo="REENVIAR_LINK_DPS"
          disableDelete
        />
        <ModalGerenciarSocios
          dadosPessoa={validarSocio}
          handleClose={() => setOpenModalSolicitarDps(!openModalSolicitarDps)}
          handleExcluirSocio={cpf =>
            handleExcluirSocio(cpf, () =>
              setOpenModalSolicitarDps(!openModalSolicitarDps),
            )
          }
          handleSubmitForm={handleSubmitSolicitarDps}
          loading={ConditionalUtils.checkIfSomeItemsAreTrue([
            servicePJ.loadingConsultarSubscricao,
            serviceSolicitarDps.loadingSolicitarDps,
          ])}
          openModal={openModalSolicitarDps}
          tipo="ENVIAR_DPS"
          disableDelete
        />

        <ModalRetornoAcaoDps
          openModal={modalRetornoAcaoDps.isOpen}
          message={modalRetornoAcaoDps.message}
          handleClose={handleSubmitRetornoAcaoDps}
        />
      </Card.Content>
    </Card>
  );
};

export default TabelaSocios;
