// @ts-nocheck
import ValidateResult from 'main/features/Validation/types/ValidateResult';

export type Fundo = {
  valor: number;
  valorMinimo: number;
  codFundo: string;
  codReserva?: string;
  perfilRisco: string;
  rentabilidade: number;
  saldo: number;
  contribuicaoAtual: number;
  selecionado: boolean;
  descFundo: string;
  isParcial?: boolean;
  desabilitado?: boolean;
  tipoFundo?: string;
  percentual?: string;
  valorTransferencia?: string;
  staTipRetirada?: string;
  tipoTransferenciaFundo?: string;
  check?: boolean;
  dadoGenerico?: string | React.JSX.Element;
  tipoTransferencia?: string;
  perfil?: React.JSX.Element;
  valor?: string;
  perfilRedistribuido?: string | number;
  valorRedistribuido?: string | number;
  valorContribuicao?: string | number;
};

export type TipoInputRefs = {
  validate: () => ValidateResult;
  value: number;
} | null;

export type TipoRetornoValidaFundos = {
  tabelaAlterada: Fundo[] | undefined;
  valoresDiferentes: boolean | undefined;
  mensagemValoresDiferentes: string | undefined;
};

export type valorTotalTabelaFundos = {
  value: number;
  cod: string;
  selecionado: boolean;
};

export type TipoResgate =
  | 'totalEParcial'
  | 'somenteTotal'
  | 'somenteParcial'
  | 'somenteUmTotal';

export type QuantidadeMaximaFundosPorTipos = {
  existente: {
    quantidade: number;
  };
  novo: {
    quantidade: number;
  };
};

export enum EnumEtapas {
  selecionarFundosOrigem = 'selecionarFundosOrigem',
  selecionarFundosDestino = 'selecionarFundosDestino',
  confirmarTransferencia = 'confirmarTransferencia',
  redistribuirValores = 'redistribuirValores',
  assinatura = 'assinatura',
  concluirComprovante = 'concluirComprovante',
}
