import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';

export const COLUNAS_TABELA_COBERTURAS = [
  {
    name: '',
    selector: (row: APOLICE_TYPES.ITabelaApoliceCoberturasItens) =>
      row.descricao,
  },
  {
    name: '',
    selector: (row: APOLICE_TYPES.ITabelaApoliceCoberturasItens) => row.valor,
    cell: (data: APOLICE_TYPES.ITabelaApoliceCoberturasItens) => {
      return data.valor ? tryGetMonetaryValueOrDefault(data.valor) : '-';
    },
    maxWidth: '200px',
    center: true,
  },
];

export const SEM_DADOS_COBERTURAS =
  'Não há dados de cobertura a serem exibidos.';
