// @ts-nocheck
import Icon from 'main/components/Icon';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import { IDataTableColumn } from 'react-data-table-component';
import {
  IDadosBancariosAporte,
  IDadosFundosAporte,
  TDadosFundosAporteColumns,
} from '../types/AporteResponse';

export const DECLARACAO_ORIGEM_DOS_RECURSOS =
  'De acordo com a Art. 32 e 36 da Circular SUSEP 612/2020 – Nos casos de operações, inclusive propostas de operações, envolvendo pessoas expostas politicamente, seus familiares, representantes, estreitos colaboradores ou pessoas jurídicas de que participem deve ser observado o procedimento de diligências para estabelecer a origem dos recursos, bem como o monitoramento de contribuições de alto valor financeiro.';

export const DADOS_BANCARIOS_NAO_ENCONTADOS = 'Dados bancários não encontrados';

export const INFORMACAO_PAGAMENTO_VIA_BOLETO = {
  COMECO: 'É importante ',
  MEIO: 'pagar o boleto até a data de vencimento ',
  FIM: 'em qualquer agência ou pelo Internet Banking da CAIXA.',
};

export const DADOS_GERAR_APORTE_NAO_ENCONTADOS =
  'Dados referente a aporte adicional  não encontrados';

export const DADOS_DISTRIBUICAO_APORTE_NAO_ENCONTADOS =
  'Dados referente a distribuição de fundo(s) não encontrado(s)';

export const PEP_NAO_DESEJA_INFORMAR_ORIGEM_RECURSOS = `A falta de informação sobre a origem de recursos será considerada como resistência ao fornecimento de informações necessárias para apuração de risco. ${'\n'} Isso poderá implicar em agravamento do perfil de risco do Cliente. ${'\n'} O Cliente está ciente disso?`;

export const INFORMACAO_CONTA_BANCARIA = {
  CONTA_EXISTENTE:
    'A conta selecionada não pode ser utilizada para o aporte, para prosseguir, indique outra.',
  NOVA_CONTA:
    'Informações da conta inválida. Verifique os campos e tente novamente.',
};

export const FORMA_PAGAMENTO_APORTE_ADICIONAL =
  'Para alcançar os objetivos mais rápido, o cliente pode fazer contribuições adicionais quando quiser.';

export const FORMA_DISTRIBUICAO_FUNDOS = {
  ESCOLHA_FORMA:
    'Escolha como o valor da nova contribuição será distribuído entre os fundos.  O certificado deve permanecer com no máximo de ',
};

export const NAO_HA_DADOS_TABELA = 'Não há dados para exibir.';

export const FORMA_PAGAMENTO_DEBITO = {
  DATA_SELECIONADA: 'Caso a data selecionada seja ',
  FERIADO: 'feriado',
  DEBITO_SERA: ', o débito se portergará para o ',
  PROXIMO_DIA_UTIL: 'próximo dia útil.',
};

export const FORMA_PAGAMENTO_APORTE_LABELS = {
  PARAMETRO_DATA_PAGAMENTO: '5',
  INDIQUE_FORMA_PAGAMENTO: 'Indique a forma de pagamento:',
  ESCOLHA_OPCAO_PAGAMENTO: 'Escolha a opção',
  BOLETO_BANCARIO: 'Boleto Bancário',
  DEBITO_EM_CONTA: 'Débito em Conta',
  SELECIONE_DEBITO_CONTA: 'Selecione a Data do Débito',
  VALOR_CONTRIBUICAO: 'Valor da Contribuição',
  VALOR_CONTRIBUICAO_INICIAL: '0,00',
  VALOR_MINIMO: 'Valor Mínimo',
};

export const COMPROVANTE_APORTE_LABELS = {
  OPERACAO_REALIZADA: 'Operação realizada com sucesso.',
  DESCRICAO_CONTA_DEBITO_INDICADA:
    'A conta indicada será utilizada exclusivamente para este aporte.',
  MENSAGEM_EXCECAO: 'Não foi possível efetuar o Aporte.',
};

export const FUNDOS_DISTRIBUICAO_APORTE_LABELS = {
  DISTRIBUICAO_FUNDOS: 'Distribuição Entre os Fundos',
  FUNDOS_EXISTENTES: 'Fundos Existentes:',
  FUNDOS_NOVOS: 'Fundos Novos:',
  RESTANTE: 'Restante:  ',
  TOTAL_SELECIONADO: 'Total dos Fundos Selecionados:  ',
};

export enum NumeroInstituicaoCaixa {
  CONTA_CAIXA = '104',
}

export const QUANTIDADE_MAXIMA_FUNDOS_PERMITIDO = {
  TITULO: 'Quantidade Máxima Permitida de Fundos',
  MENSAGEM: 'A quantidade máxima permitida de alteração de fundos novos é de',
};

export const enum enumTipoMensagemAporte {
  ORIGEM_DOS_RECURSOS = '99',
  CONTA_BANCARIA_EXISTENTE = '100',
  CONTA_BANCARIA_NOVA = '101',
}

export const enum enumValidacaoContaBancaria {
  CONTA_BANCARIA_CADASTRADA_VALIDADA = '00',
}

export const enum enumTituloMensagemContaBancaria {
  CONTA_SELECIONADA = 'Conta bancária selecionada inválida',
  CONTA_DIGITADA = 'Conta bancária digitada inválida',
}

export const enum enumOrigemRecursos {
  NAO_DESEJA_INFORMAR = '99',
  INFORMAR_ORIGEM_MANUALMENTE = '06',
  HEADER_DEFAULT = 'Declaração de Origem dos Recursos',
}

export const enum enumNomeBancos {
  CAIXA_ECONOMICA = 'CAIXA ECONOMICA FEDERAL',
}

export const enum enumNumeroBancos {
  CAIXA_ECONOMICA = '104',
}

export const enum enumTipoClientePep {
  TITULAR = 'T',
  RELACIONADO = 'R',
  NENUM = 'null',
}

export const enum enumTipoFundo {
  DEFAULT = '05',
  Novo = 'novo',
  Existente = 'existente',
}

export const TAMANHO_PERCENTUAL_PAGINA = '0.66';

export const CODIGO_REQUISICAO_EMAIL = 'APORTE';

export const VALOR_MINIMO_DECLARACAO_ORIGEM_APORTE = 100000;

export const DIGITO_AGENCIA_DEFAULT = '0';

export const VALOR_CONTRIBUICAO = '0';

export const VALOR_MINIMO_CONTRIBUICAO = 50;

export const PEP_POSITIVO = {
  SIM: 'SIM',
  NAO: 'NÃO',
};

export const STATUS_COLORS: APORTE_TYPES.IStatusCOlorsProp = {
  A: 'primary',
  E: 'inactive',
  U: 'warning',
  C: 'error',
};

export const COLUNAS_TABELA_CONTAS_BANCARIAS_APORTE: IDataTableColumn<IDadosBancariosAporte>[] =
  [
    {
      name: <Icon name="homeFamily" />,
      selector: row => row.contaSelecionada,
      maxWidth: '80px',
      minWidth: '80px',
    },
    {
      name: 'Banco',
      selector: row => row.banco,
      minWidth: '350px',
      center: true,
      wrap: true,
    },
    {
      name: 'Agência',
      selector: row => row.numeroAgencia,
      minWidth: '120px',
      center: true,
    },
    {
      name: 'Tipo da Conta',
      selector: row => row.operacao,
      minWidth: '350px',
      center: true,
      wrap: true,
    },
    {
      name: 'Conta',
      selector: row => row.conta,
      minWidth: '180px',
      center: true,
    },
  ];

export const COLUNAS_TABELA_DISTRIBUIR_APORTE: IDataTableColumn<IDadosFundosAporte>[] =
  [
    {
      name: 'Fundo',
      selector: row => row.descricaoFundo,
      sortable: true,
    },
    {
      name: 'Perfil de Risco',
      selector: row => row.perfil,
      maxWidth: '180px',
      sortable: true,
      center: true,
    },
    {
      name: 'Rentabilidade (Últimos 12 Meses)',
      selector: row => row.rentUlt12Meses,
      maxWidth: '180px',
      sortable: true,
      center: true,
    },
    {
      name: 'Saldo da Reserva',
      selector: row => row.saldo,
      maxWidth: '180px',
      sortable: true,
      center: true,
    },
    {
      name: 'Valor do Aporte',
      selector: row => row.valorContribuicao,
      maxWidth: '180px',
      sortable: true,
      center: true,
    },
  ];

export const COLUNAS_TABELA_FUNDOS_APORTE_CONFIRMACAO: IDataTableColumn<IDadosFundosAporte>[] =
  [
    {
      name: 'Fundo',
      selector: row => row.descricaoFundo,
      sortable: true,
    },
    {
      name: 'Perfil de Risco',
      selector: row => row.perfil,
      sortable: true,
      center: true,
    },
    {
      name: 'Valor do Aporte',
      selector: row => row.valor,
      maxWidth: '180px',
      sortable: true,
      center: true,
    },
  ];

export const COLUNAS_TABELA_GERAR_APORTE = (exibeOculta: boolean) => {
  return [
    {
      name: 'Certificado',
      selector: row => row.numeroCerfificado,
    },
    {
      name: 'Forma de Pagamento',
      selector: row => row.formaPagamento,
      minWidth: '180px',
      wrap: true,
    },
    {
      name: 'Banco',
      selector: row => row.banco,
      omit: exibeOculta,
      center: true,
    },
    {
      name: 'Operação',
      selector: row => row.operacao,
      minWidth: '200px',
      omit: exibeOculta,
      center: true,
      wrap: true,
    },
    {
      name: 'Agência',
      selector: row => row.agencia,
      maxWidth: '100px',
      omit: exibeOculta,
      center: true,
    },
    {
      name: 'Conta Bancária',
      selector: row => row.contaBancaria,
      minWidth: '140px',
      omit: exibeOculta,
      center: true,
    },
    {
      name: 'Data Débito',
      selector: row => row.dataPgamento,
      minWidth: '150px',
      omit: exibeOculta,
      center: true,
    },
    {
      name: 'Valor do Aporte',
      selector: row => row.valorAporte,
      minWidth: '150px',
    },
  ] as IDataTableColumn<APORTE_TYPES.IGerarDadosAporte>[];
};

export const COLUNAS_TABELA_FUNDOS_APORTE: IDataTableColumn<TDadosFundosAporteColumns>[] =
  [
    {
      name: 'Fundo',
      selector: row => row.descricaoFundo,
      sortable: true,
    },
    {
      name: 'Perfil de Risco',
      selector: row => row.perfil,
      maxWidth: '170px',
      sortable: true,
      center: true,
    },
    {
      name: 'Rentabilidade (Últimos 12 Meses)',
      selector: row => row.rentUlt12Meses,
      maxWidth: '120px',
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: 'Saldo da Reserva',
      selector: row => row.saldo,
      maxWidth: '180px',
      sortable: true,
      center: true,
    },
    {
      name: 'Valor do Aporte',
      selector: row => row.valor,
      maxWidth: '160px',
      sortable: true,
      center: true,
    },
  ];

export const ORIGEM_RECURSOS = [
  {
    id: '07',
    descricao: 'Aplicações ﬁnanceiras',
  },
  {
    id: '01',
    descricao: 'Herança familiar',
  },
  {
    id: '02',
    descricao: 'Economias',
  },
  {
    id: '03',
    descricao: 'Participação de Lucros',
  },
  {
    id: '04',
    descricao: 'Indenização',
  },
  {
    id: '05',
    descricao: 'Prêmio de Loteria',
  },
  {
    id: '08',
    descricao: 'Premiação de Sorteios',
  },
  {
    descricao: 'Venda de Imóvel',
    id: '09',
  },
  {
    id: '10',
    descricao: 'Venda de Veículo',
  },
  {
    descricao: 'Obtenção de empréstimo ou financiamento',
    id: '11',
  },
  {
    descricao: 'Não desejo informar',
    id: '99',
  },
];
