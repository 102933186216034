import React from 'react';
import { Grid, Text, Divider } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { Beneficios, TCoberturaData } from 'previdencia/types/Beneficiarios';
import { ICertificadoBeneficioPrevidencia } from 'main/types/CardPrevidencia/ICertificadoBeneficioPrevidencia';
import { coberturasObject } from '../../factories/coberturasFactory';
import * as S from './styles';
import { IDataTableColumn } from 'react-data-table-component';

type CoberturasProps = {
  coberturas: Beneficios[] | undefined;
  certificados: ICertificadoBeneficioPrevidencia[];
};

const colunas: IDataTableColumn<TCoberturaData>[] = [
  {
    name: 'Cobertura',
    selector: row => row.cobertura,
    grow: 2,
    wrap: true,
    minWidth: '140px',
  },
  {
    name: 'Prazo (anos)',
    selector: row => row.prazo,
    minWidth: '140px',
  },
  {
    name: 'Valor de Contribuição',
    selector: row => row.valorContribuicao,
    minWidth: '140px',
  },
  {
    name: 'Valor do Benefício',
    selector: row => row.valorBeneficio,
    minWidth: '140px',
  },
  {
    name: 'Status',
    selector: row => row.situacao,
    minWidth: '140px',
  },
];

const Coberturas: React.FC<CoberturasProps> = ({
  coberturas,
  certificados,
}) => {
  return (
    <S.Coberturas>
      <Grid margin>
        <Grid.Item xs={1}>
          <Text variant="body02-md" color="text">
            Coberturas
          </Text>
          <Divider />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Table
            responsive
            data={coberturasObject(coberturas, certificados) ?? []}
            columns={colunas}
            noDataComponent="Não há dados para exibir."
          />
        </Grid.Item>
      </Grid>
    </S.Coberturas>
  );
};

export default Coberturas;
