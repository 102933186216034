// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import Solicitar from 'dps/features/solicitarDps/pages/Solicitar';
import StatusDPS from 'dps/features/consultaStatusDPS/pages/StatusDPS';
import { PendentesDPS } from 'dps/features/pendentesDps/pages/PendentesDPS';

const routes: IPrivateRoute[] = [
  {
    path: '/dps/solicitar',
    component: Solicitar,
    key: 'solicitar-DPS',
    breadcrumb: 'Solicitar DPS',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/dps/atualizar',
    component: StatusDPS,
    key: 'solicitar-DPS',
    breadcrumb: 'Solicitar DPS',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/dps/consultar-status',
    component: StatusDPS,
    key: 'consultar-status',
    breadcrumb: 'Consultar Status',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/dps/pendentes',
    component: PendentesDPS,
    key: 'pendentes',
    breadcrumb: 'DPS Pendentes',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
];

export const painelDPSBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/dps': null,
};

const RotasDPS = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasDPS;
