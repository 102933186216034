import DataTable from 'react-data-table-component';
import styled from 'styled-components';

export const TabelaInformacoes = styled(DataTable)<any>`
  border-radius: 0.625rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  margin: 1.25rem 0;

  .rdt_TableCol {
    font-size: 1rem;
    color: ${props => props.theme.color.support['01']};
    font-weight: bold;
  }

  .rdt_TableRow {
    background-color: ${props => props.theme.color.neutral['07']};
    border-bottom: 0.0625rem solid ${props => props.theme.color.neutral['05']};
    transition: background-color 0.3s;
    font-size: 0.875rem;
    &:hover {
      background-color: ${props => props.theme.color.support['03']};
    }
  }

  .rdt_TableCell {
    padding: 0.625rem 0.9375rem;
    color: #a4a5a9;
  }

  .rdt_TableFooter {
    background-color: ${props => props.theme.color.neutral['05']};
    color: white;
    text-align: right;
    padding: 0.625rem 0.9375rem;
    border-radius: 0 0 0.625rem 0.625rem;
  }
`;
