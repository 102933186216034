import { IDataTableColumn } from 'react-data-table-component';
import { TContribuicoesConvertidoDataColumn } from '../types/ResponseContribuicoes';
import {
  ordenaDataPagamento,
  ordenaDataVencimento,
  ordenaValorContribuicao,
} from '../utils/contribuicoes';

export const CODIGO_STATUS_COBRANCA = {
  GERADA: 'GE',
  COBRADA: 'CO',
  PAGO: 'BT',
  CANCELADA: 'CC',
  CANCELADA_INADIMPLENCIA: 'CI',
  INADIMPLENTE: 'IN',
  PARCIALMENTE_PAGO: 'BP',
  PENDENTE: 'CB',
};

export const STATUS_COBRANCA_CONVERTIDO = {
  PAGA: 'PAGA',
  NAO_PAGO: 'NÃO PAGO',
  PENDENTE: 'PENDENTE',
  CANCELADA: 'CANCELADA',
};

export const CODIGO_MEIO_PAGAMENTO = {
  BOLETO: 'FC',
  DEBITOCONTA: 'CB',
};

export const colunas: IDataTableColumn<TContribuicoesConvertidoDataColumn>[] = [
  {
    name: 'VENCIMENTO',
    selector: row => row.dataVencimento,
    wrap: true,
    sortable: true,
    minWidth: '120px',
    sortFunction: ordenaDataVencimento,
  },
  {
    name: 'PAGAMENTO',
    selector: row => row.dataPagamento,
    minWidth: '100px',
    sortable: true,
    sortFunction: ordenaDataPagamento,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: row => row.formaPagamento,
    minWidth: '140px',
    sortable: true,
  },
  {
    name: 'CONTRIBUIÇÃO',
    selector: row => row.valorContribuicao,
    minWidth: '120px',
    sortable: true,
    sortFunction: ordenaValorContribuicao,
  },
  {
    name: 'STATUS',
    selector: row => row.statusRequisicao,
    minWidth: '205px',
    sortable: true,
  },
  {
    name: '2º VIA',
    selector: row => row.boleto,
    minWidth: '220px',
    sortable: true,
  },
];
