// @ts-nocheck
import { useContext, useState } from 'react';
import { usePecoConsultarSaldo } from './usePecoConsultarSaldo';
import { usePecoTransferenciaConsultar } from './usePecoTransferenciaConsultar';
import { AppContext } from 'main/contexts/AppContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import { useHistory } from 'react-router';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  IDadosCertificados,
  IDadosCobertura,
} from '../types/TransferenciaConsultar';

export const useResumoTransferencia = () => {
  const history = useHistory();

  const { cliente } = useContext(AppContext);
  const { setFeatureData, clearFeatureData } =
    usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const {
    response,
    loading,
    saldoTotal,
    valorContribuicao,
    valorPeculio,
    valorPensao,
    prazoPensao,
    responseListarMelhoresTaxas,
    loadingListarMelhoresTaxas,
    mensagemError,
  } = usePecoTransferenciaConsultar();
  const { response: responseConsultarSaldo, loading: loadingConsultarSaldo } =
    usePecoConsultarSaldo();

  const [familiaFundoEscolhida, setFamiliaFundoEscolhida] = useState(0);

  const obterCoberturasNaoComercializadas = (
    dadosResponse?: IDadosCertificados,
  ) => {
    return dadosResponse?.coberturas?.dadosCoberturas?.filter(
      cobertura => cobertura.comercializado === false,
    );
  };

  const handleNextStep = () => {
    setFeatureData({
      saldoReserva: saldoTotal,
      valorContribuicao,
      valorPeculio,
      valorPensao,
      prazoPensao,
      familiaFundoEscolhida,
      coberturas: tryGetValueOrDefault(
        [response?.coberturas.dadosCoberturas],
        [] as IDadosCobertura[],
      )?.filter(cobertura => cobertura.risco && cobertura.comercializado),
      coberturasNaoComerciaizadas: obterCoberturasNaoComercializadas(response),
      certificado: cliente?.numCertificado,
      perfilFundoAtual: {
        nivel: tryGetValueOrDefault([response?.nivelPerfilFundoAtual], 0),
        perfil: tryGetValueOrDefault([response?.perfilFundoAtual], ''),
      },
    });
    history.push(
      '/cliente/produtos/previdencia/transferencia-alteracao-taxa/distribuicao',
    );
  };

  const isDisabledButton = () => familiaFundoEscolhida === 0;

  return {
    existeCoberturasNaoComercializas:
      response?.coberturas.existeCoberturasNaoComercializas,
    loading,
    coberturasNaoComerciaizadas: obterCoberturasNaoComercializadas(response),
    loadingConsultarSaldo,
    coberturas: response?.coberturas,
    saldoTotal,
    fundos: response?.fundosVgbl,
    responseConsultarSaldo,
    responseListarMelhoresTaxas,
    loadingListarMelhoresTaxas,
    mensagemError,
    clearFeatureData,
    handleNextStep,
    setFamiliaFundoEscolhida,
    isDisabledButton,
  };
};
