import { Button, Card, Disclaimer, Text } from '@cvp/design-system/react';
import * as P from 'contratosPrestamista/components/prestamistaStyles';
import * as MSG from 'contratosPrestamista/constants';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { Skeleton as TableSkeleton } from 'main/components/Table';
import * as S from 'main/components/Table/customStyles';
import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import TableFilters from 'main/components/Table/TableFilter/TableFilter';
import { formatarData } from 'main/utils';
import masks from 'main/utils/masks';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { useMemo, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import ModalUploadArquivo from '../components/ModalUploadArquivo';
import { useObterListaPropostas } from '../hooks/useObterPropostas';
import {
  IPropostaDataColumn,
  IResponseListaProposta,
} from '../types/IResponseListaProposta';

const PropostasPendentes = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [propostaSelecionada, setPropostaSelecionada] =
    useState<IResponseListaProposta>({} as IResponseListaProposta);
  const { data, isLoading } = useObterListaPropostas();

  const filterOptions: IFilterOption[] = [
    {
      key: 'cpfCnpj',
      value: 'CPF/CNPJ',
      type: 'text',
      unmask: masks.cpf.unmask,
    },
    { key: 'numeroProposta', value: 'Proposta', type: 'text' },
    {
      key: 'dataHoraEmissaoDaProposta',
      value: 'Data da proposta',
      type: 'date',
    },
  ];

  const handlePropostaSelectedToUpload = (
    numeroProposta: IResponseListaProposta | any,
  ) => {
    setPropostaSelecionada(numeroProposta);
    setOpenModal(!openModal);
  };

  const colunas: IDataTableColumn<IPropostaDataColumn>[] = useMemo(
    () => [
      {
        name: 'Proposta',
        selector: row => row.numeroProposta,
        sortable: true,
        width: '250px',
        cell: proposta => (
          <S.LinkedTable
            to={{
              pathname: `/prestamista/propostas/pendentes/${proposta.numeroProposta}/detalhes`,
              state: {
                proposta,
              },
            }}
          >
            {proposta.numeroProposta}
          </S.LinkedTable>
        ),
      },
      {
        name: 'Data da proposta',
        sortable: true,
        selector: row => row.dataHoraEmissaoDaProposta,
        cell: ({ dataHoraEmissaoDaProposta }) => (
          <span>{formatarData(dataHoraEmissaoDaProposta)}</span>
        ),
      },
      {
        name: 'Agência',
        selector: row => row.codigoAgenciaVenda,
        sortable: true,
      },
      {
        name: 'Valor Prêmio Líquido',
        selector: row => row.valorPremioLiquido,
        sortable: true,
        cell: (cell: IPropostaDataColumn) => (
          <span>{formatarValorPadraoBrasileiro(cell.valorPremioLiquido)}</span>
        ),
      },
      {
        name: 'CPF/CNPJ',
        sortable: true,
        selector: row => row.cpfCnpj,
        cell: ({ cpfCnpj }) => <span>{maskCpfCnpj(cpfCnpj)}</span>,
      },
      {
        name: 'Ação',
        selector: row => row.numeroProposta,
        cell: proposta => (
          <Button
            data-testid={`uploadButton-${proposta.numeroProposta}`}
            small
            onClick={() => handlePropostaSelectedToUpload(proposta)}
          >
            Upload
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Card style={{ minHeight: 'auto' }}>
        <Card.Content data-testid="tabelaListaPropostas">
          {isLoading ? (
            <TableSkeleton colunas={colunas} />
          ) : (
            <>
              <TableFilters
                dataToFilter={data}
                filterOptions={filterOptions}
                filterTextPartial
                defaultFilter={{
                  filterType: 'date',
                  initialDate: new Date('2022-08-01T00:00:00'),
                  endDate: new Date(),
                  filter: 'dataHoraEmissaoDaProposta',
                  text: '',
                }}
              >
                {dataFiltered => (
                  <>
                    <Text variant="body-medium1" margin>
                      Para visualizar as propostas antes de 01/08/2022 utilize
                      os filtros acima
                    </Text>
                    <S.Table
                      responsive
                      noHeader
                      data={dataFiltered}
                      columns={colunas as IDataTableColumn[]}
                      noDataComponent={
                        data !== undefined ? MSG.MENSAGEM_FILTRO_SEM_DADOS : ''
                      }
                      pagination
                      paginationPerPage={10}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Items por página',
                        rangeSeparatorText: 'de',
                      }}
                      style={{ marginTop: '3rem' }}
                    />
                  </>
                )}
              </TableFilters>

              <RenderConditional condition={data === undefined}>
                <P.Disclaimer variant="error">
                  <Disclaimer.Content
                    text={MSG.PROPOSTA_SEM_DADOS_RETORNO_ENDPOINT}
                    icon={<Icon name="information" />}
                  />
                </P.Disclaimer>
              </RenderConditional>
            </>
          )}
        </Card.Content>
      </Card>
      {openModal && (
        <ModalUploadArquivo
          proposta={propostaSelecionada}
          open={openModal}
          handleClose={setOpenModal}
        />
      )}
    </>
  );
};

export default PropostasPendentes;
