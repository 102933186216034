import { Text } from '@cvp/design-system/react';

import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import {
  IAlicotasAgrupadasFundosAliquota,
  IAlicotasAgrupadasFundosAliquotaValores,
  TAlicotasAgrupadasFundosAliquotaFactory,
} from 'previdencia/types/AlicotasAgrupadas';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

export const TIPOS_RESGATE = {
  PARCIAL: 'Parcial',
  TOTAL: 'Total',
};

export const TEXTOS_REGIME_TRIBUTARIO = {
  TITULO: 'Alteração de Regime Tributário',
  DESCRICAO:
    'Confira as deduções aplicáveis para cada regime tributário e selecione uma opção para prosseguir.',
  TITULO_DECLARACAO: 'Declaração',
  INFO_CHECKBOX: `O cliente confirma a opção pelo regime de tributação de alíquotas selecionada, previsto na IN RFB Nº 2.209 de 06 de agosto de 2024, para o certificado indicado, ciente de que esta opção é irretratável.`,
};

export const MODAIS_REGIME_TRIBUTARIO: REGIME_TRIBUTARIO_TYPES.IModaisRegimeTributario =
  {
    modalTipo: false,
    modalDocusign: false,
    modalAlertaContatos: false,
    modalAlertaAlteracaoExpirada: false,
  };

export const TEXTOS_REGIME_TRIBUTARIO_MSG = {
  SUCESSO: 'Solicitação recebida com sucesso!',
};

export const ALTERACAO_REGIME_TRIBUTARIO = {
  PODE_ALTERAR: 'S',
  NAO_PODE_ALTERAR: 'N',
};

export const COLUNAS_ALIQUOTA = [
  {
    name: 'Fundos',
    selector: (row: IAlicotasAgrupadasFundosAliquota) => row.descricaoFundo,
    center: true,
    minWidth: '200px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) =>
      getTernaryResult<React.JSX.Element | string>(
        row.descricaoFundo !== TIPOS_RESGATE.TOTAL,
        <Text variant="body02-md" align="center">
          {tryGetValueOrDefault([row.descricaoFundo], '-')}
        </Text>,
        tryGetValueOrDefault([row.descricaoFundo], '-'),
      ),
    wrap: true,
  },
  {
    name: 'Saldo Total',
    selector: (row: IAlicotasAgrupadasFundosAliquotaValores) => row.saldoTotal,
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) =>
      tryGetMonetaryValueOrDefault(row.saldoTotal),
    center: true,
    minWidth: '180px',
  },
  {
    name: 'Alíquota IRPF (%)',
    selector: (row: IAlicotasAgrupadasFundosAliquotaValores) => row.aliquota,
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) =>
      getTernaryResult(
        row.descricaoFundo !== TIPOS_RESGATE.TOTAL,
        tryGetValueOrDefault([row.aliquota], '-'),
        '',
      ),
    center: true,
  },
  {
    name: 'Saldo Disponível',
    selector: (row: IAlicotasAgrupadasFundosAliquotaValores) =>
      row.valorDisponivel,
    center: true,
    minWidth: '180px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) => {
      return getTernaryResult(
        row.descricaoFundo !== TIPOS_RESGATE.TOTAL,
        tryGetMonetaryValueOrDefault(row.valorDisponivel),
        '',
      );
    },
  },
  {
    name: 'Saldo Indisponível',
    selector: (row: IAlicotasAgrupadasFundosAliquotaValores) =>
      row.valorIndisponivel,
    center: true,
    minWidth: '180px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) => {
      return getTernaryResult(
        row.descricaoFundo !== TIPOS_RESGATE.TOTAL,
        tryGetMonetaryValueOrDefault(row.valorIndisponivel),
        '',
      );
    },
  },
];

export const NO_DATA_ALIQUOTA =
  'Não há opção de alíquota para o atual certificado.';

export const conditionalRowStylesAliquota = [
  {
    when: (row: { data: string; descricaoFundo: string }) =>
      tryGetValueOrDefault([row.data, row.descricaoFundo], '') ===
      TIPOS_RESGATE.TOTAL,
    style: {
      backgroundColor: '#005FC8',
      color: 'white',
    },
  },
];
