import { IDataTableColumn } from 'react-data-table-component';
import { CarteiraUsuario } from '../types/enum';
import { ItensExtratoData } from '../types/ResponseExtratoUnificado';
import * as S from 'previdencia/features/PosicaoConsolidada/components/TabelaCarteiraInvestimento/styles';

const defaultMinWidth = {
  minWidth: '120px',
};

export const columns: IDataTableColumn<ItensExtratoData>[] = [
  {
    id: '1',
    name: 'Certificado',
    selector: row => row.contaId,
    minWidth: '125px',
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.contaId}
      </S.CelulaCarteiraUsuario>
    ),
  },
  {
    id: '2',
    name: 'Reserva',
    selector: row => row.saldoTotal,
    minWidth: '160px',
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.saldoTotal}
      </S.CelulaCarteiraUsuario>
    ),
  },
  {
    id: '3',
    name: 'Fundo',
    selector: row => row.descricaoCompletaFundo,
    minWidth: '150px',
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.descricaoCompletaFundo}
      </S.CelulaCarteiraUsuario>
    ),
  },
  {
    id: '4',
    name: '% da Carteira',
    selector: row => row.percentualSaldo,
    minWidth: '85px',
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.percentualSaldo}
      </S.CelulaCarteiraUsuario>
    ),
  },
  {
    id: '5',
    name: 'Rentabilidade 12 meses',
    selector: row => row.lucratividadeUltimos12Meses,
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.lucratividadeUltimos12Meses}
      </S.CelulaCarteiraUsuario>
    ),
    ...defaultMinWidth,
  },
  {
    id: '6',
    name: 'Volatilidade 12 meses',
    selector: row => row.flutuacaoUltimos12Meses,
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.flutuacaoUltimos12Meses}
      </S.CelulaCarteiraUsuario>
    ),
    ...defaultMinWidth,
  },
  {
    id: '7',
    name: 'Tx. de Adm.',
    selector: row => row.taxaAdministrativa,
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.taxaAdministrativa}
      </S.CelulaCarteiraUsuario>
    ),
    minWidth: '50px',
  },
  {
    id: '8',
    name: 'Modalidade e Tributação',
    selector: row => row.modalidadeTributacao,
    cell: row => (
      <S.CelulaCarteiraUsuario
        contaId={row.contaId === CarteiraUsuario.CARTEIRA}
      >
        {row.modalidadeTributacao}
      </S.CelulaCarteiraUsuario>
    ),
    minWidth: '115px',
  },
  {
    id: '9',
    name: 'Regulamento e Lâmina',
    selector: row => row.documentos,
    minWidth: '150px',
    center: true,
  },
];
