import { Text, Display } from '@cvp/design-system/react';
import React from 'react';
import {
  COLUNA_ATENDIMENTO_TELEFONE_UTEIS,
  INFORMACOES_TELEFONE_UTEIS,
  TITULO_TELEFONE_UTEIS,
} from 'atendimento/features/TelefoneUteis/constants/constants';
import { TabelaInformacoes } from 'atendimento/features/TelefoneUteis/styles/TabelaTelefonesUteis';

const TelefonesUteis: React.FC = () => {
  return (
    <Display>
      <Text color="primary" variant="headline-04">
        {TITULO_TELEFONE_UTEIS}
      </Text>
      <TabelaInformacoes
        columns={COLUNA_ATENDIMENTO_TELEFONE_UTEIS}
        data={INFORMACOES_TELEFONE_UTEIS}
        noHeader
      />
    </Display>
  );
};
export default TelefonesUteis;
