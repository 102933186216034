import React from 'react';
import { Text, Card, Button, Display } from '@cvp/design-system/react';
import { CardResumoPrestamista } from 'prestamista/components/CardResumoPrestamista';
import usePrestNavigation from 'prestamista/hooks/usePrestNavigation';
import { useDadosSeguradoPrestamista } from 'prestamista/features/dadosSegurado/hooks/useDadosSeguradoPrestamista';
import TabelaDadosSegurado from 'prestamista/features/dadosSegurado/components/TabelaDadosSegurado';
import * as S from 'prestamista/features/dadosSegurado/pages/DadosSegurado/styles';
import { usePrestamistaContext } from 'prestamista/contexts/PrestamistaContextProvider';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import {
  IDadosSeguradoContextData,
  StatusCertificado,
} from '../../types/DadosSegurado';

const DadosSegurado: React.FC = () => {
  const { goHomePrest } = usePrestNavigation();

  const { featureData } = usePrestamistaContext<IDadosSeguradoContextData>();

  const dadosCertificadoPrestamista = featureData?.dadosCertificadoPrestamista;

  const { handleUpdateDadosSegurado, loadingDadosSegurado } =
    useDadosSeguradoPrestamista();

  return (
    <>
      <CardResumoPrestamista />

      <Display type="display-block">
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Text variant="headline-05" color="primary" margin>
              Dados do Segurado
            </Text>

            <TabelaDadosSegurado />

            <S.ContainerButton>
              <Button
                variant="outlined"
                onClick={goHomePrest}
                data-testid="btnVoltarTelaInicial"
              >
                Voltar
              </Button>
              <RenderConditional
                condition={checkIfAllItemsAreTrue([
                  dadosCertificadoPrestamista?.statusCertificado ===
                    StatusCertificado.ATIVO,
                ])}
              >
                <Button
                  data-testid="btnAtualizarDadosSegurado"
                  onClick={handleUpdateDadosSegurado}
                  variant="primary"
                  loading={loadingDadosSegurado}
                  disabled={loadingDadosSegurado}
                >
                  Atualizar Dados
                </Button>
              </RenderConditional>
              <Button
                variant="secondary"
                data-testid="btnImprimirDadosSegurado"
                onClick={window.print}
              >
                Imprimir
              </Button>
            </S.ContainerButton>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default DadosSegurado;
